import Utils from './utils';

export const SortColumns = {
    customer: [
        {
            value: 'customerName',
            title: 'Name',
            template: (row) => {
                return row.customerName;
            }
        },
        {
            value: 'accountCustomerId',
            title: 'Company ID',
            template: (row) => {
                return '[' + row.accountCustomerId + ']';
            }
        },
        {
            value: 'billingName',
            title: 'Billing Name',
            template: (row) => {
                return row.billingName;
            }
        },
        {
            value: 'billingProfileName',
            title: 'Billing By',
            template: (row) => {
                return row.billingProfileName;
            }
        },
        {
            value: 'creditLimit',
            title: 'Credit Limit',
            template: (row) => {
                return (row.creditLimit) ? Utils.getCurrency() + row.creditLimit : 'Unlimited';
            }
        },
        // {
        //     value: 'creditAmount',
        //     title: 'Credit Amount',
        //     template: (row) => {
        //         return row.creditAmount;
        //     }
        // },
        {
            value: 'isRequirePaymentCollection',
            title: 'Collect Payment',
            template: (row) => {
                return row.isRequirePaymentCollection === true ? "Yes" : "No";
            }
        },
        {
            value: 'noOfCharges',
            title: 'Local Services',
            template: (row) => {
                return row.noOfCharges;
            }
        },
        {
            value: 'paymentTerms',
            title: 'Payment Terms',
            template: (row) => {
                return row.paymentTerms;
            }
        },
        {
            value: 'noOfSites',
            title: 'Sites',
            template: (row) => {
                return row.noOfSites;
            }
        },
        {
            value: 'created',
            title: 'Created Time',
            template: (row) => {
                return row.createdDisplay;
            }
        },
        {
            value: 'updated',
            title: 'Last modified Time',
            template: (row) => {
                return row.updatedDisplay;
            }
        },
    ],
    localService: [
        {
            value: 'serviceTag',
            title: 'Service Name',
            template: (row) => {
                return null;
            }
        },
        {
            value: 'billingTypeDisplay',
            title: 'Billing Action',
            template: (row) => {
                return row.billingTypeDisplay;
            }
        },
        {
            value: 'chargeCategoryName',
            title: 'Billing Category',
            template: (row) => {
                return row.chargeCategoryName;
            }
        },
        {
            value: 'price',
            title: 'Rate',
            template: (row) => {
                return (row.price) ? Utils.getCurrency() + row.price : '';
            }
        },
        {
            value: 'useBinWeightQuantity',
            title: 'Link Bin Weight',
            template: (row) => {
                return row.useBinWeightQuantity === true ? "Yes" : "No";
            }
        },
        {
            value: 'nonBillableWeight',
            title: 'Non Billable Weight',
            template: (row) => {
                return row.nonBillableWeight ? row.nonBillableWeight : "";
            }
        },
    ],
    customerSite: [
        {
            value: 'siteName',
            title: 'Site Name',
            template: (row) => {
                return null;
            }
        },
        {
            value: 'zoneName',
            title: 'Group as',
            template: (row) => {
                return row.zoneName;
            }
        },
        {
            value: 'created',
            title: 'Created Time',
            template: (row) => {
                return row.createdDisplay;
            }
        },
        {
            value: 'updated',
            title: 'Last modified Time',
            template: (row) => {
                return row.updatedDisplay;
            }
        },
    ],
    geofence: [
        {
            value: 'name',
            title: 'Name',
            template: (row) => {
                return null;
            }
        },
        {
            value: 'accountMapGeofenceGroupId',
            title: 'Group',
            template: (row) => {
                return (row.group && row.group.groupName && row.group.groupName != '') ? row.group.groupName : '';;
            }
        },
        {
            value: 'created',
            title: 'Created Time',
            template: (row) => {
                return row.createdDisplay;
            }
        },
        {
            value: 'updated',
            title: 'Last modified Time',
            template: (row) => {
                return row.updatedDisplay;
            }
        },
    ],
    geofenceGroup: [
        {
            value: 'groupName',
            title: 'Name',
            template: (row) => {
                return null;
            }
        },
        {
            value: 'created',
            title: 'Created Time',
            template: (row) => {
                return row.createdDisplay;
            }
        },
        {
            value: 'updated',
            title: 'Last modified Time',
            template: (row) => {
                return row.updatedDisplay;
            }
        },
    ]
}

export const CancelToken = {
    NONE: '',
    CANCELLED: 'Request canceled due to new request',
}

export const ActiveBinNumberRequestFilter = {
    Seven: 0,
	Fourteen: 1,
	TwentyOne: 2,
	ThirtyPlus: 3
}

export const ParentJobRelation = {
    MultipleTrip: 0,
	FollowUp: 1
}

export const JobAction = {
    Save: 0,
    Draft: 1,
    Unassigned: 2,
    Assigned: 3,
    Dispatched: 4,
    Completed: 5,
    Cancelled: 6,
    SaveAndClose: 7
}

export const RepeatOrder = {
    Daily: 0,
    Weekly: 1,
    Monthly: 2 
}

export const CustomerCreditLimitAction = {
    Email: 0,
    StopService: 1,
}

export const YesNo = {
    NoValue: 0,
    NoTitle: 'No',
    YesValue: 1,
    YesTitle: 'Yes',
}

export const ActiveInactive = {
    InactiveValue: 0,
    InactiveTitle: 'Inactive',
    ActiveValue: 1,
    ActiveTitle: 'Active',
}

export const AutoSendDoDocHoursLater = {
    ImmediatelyId: 0,
    ImmediatelyName: 'Immediately',
}

export const AutoSendDoDocType = {
    Immediately: 0,
    HoursLater: 1,
};

export const BillingTypes = {
    Invoice: 0,
    Purchase: 1,
    Expense: 2
}

export const ServiceType = {
    StandardService: 0,
    BundleService: 1,
    AdditionalService: 2
}

export const CustomerSiteAvailability = {
    All: 0,
    SelectedHours: 1,
    NotAvailable: 2 
}

export const BillingDayInterval = {
    Day: 0,
    Week: 1,
    Month: 2,
    Year: 3
}

export const DayOfWeek = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 0
}

export const JobStepType = {
    Pull: 0,
    Put: 1,
    Exchange: 2,
    OnTheSpot: 3,
    Out: 4,
    Shift: 5,
    ThrowAtCustomer: 6
}

export const GanttChartContextMenuItems = {
    Edit: 1,
    Started: 4,
    Completed: 5,
    Cancel: 6,
    Failed: 7,
    Unassigned: 7,
}

export const BinActivity = {
    IN: 1,
    OUT: 0,
    NEED_ATTENTION: 2
}

export const JobStepBinActivity = {
    LoadBin: 0,
    PutBin: 1,
    ExchangeBin: 2,
    NoActivity: 3,
}

export const StepType = {
    NONE: 0,
    BIN_IN: 1,
    BIN_OUT: 2,
    BIN_EXCHANGE: 3,
    WASTE_COLLECTION: 4,
    SHIFT_IN: 5,
    SHIFT_OUT: 6,
    SHIFT_EXCHANGE: 7,
}
export const StepAddress = {
    SITE_ADDRESS: 1,
    CUSTOMER_ADDRESS: 2,
}
export const InOrOut = {
    NONE: 0,
    IN: 1,
    OUT: 2,
}
export const BinOrWaste = {
    NONE: 0,
    BIN: 1,
    WASTE: 2,
}
export const RadioOrCheckbox = {
    NONE: 0,
    RADIO: 1,
    CHECKBOX: 2,
}

export const DriverLoginDeviceType = {
    Android: 0,
    iOS: 1,
    WebPwa: 2
}

export const JobRespondType = {
    Accept: 0,
    Reject: 1
}

export const ServiceChargeType = {
    NoExpiry: 0,
    WithExpiry: 1,
    Recurring: 2
}

export const NotificationType = {
    JobUpdate: 0,
    MessageUpdate: 1
}

export const AccountDocSettingType = {
    DeliveryOrder: 0,
    JobReceipt: 1,
    WeightChit: 2
}

export const AccountDocSettingVariables = {
    Show: 0,
    String: 1,
    Label: 3
}

export const JobPaymentType = {
    Cash: 0,
    Cheque: 1,
    Transfer: 2,
    Other: 3,
}

export const DefaultLoaction = {
    None: 0,
    DefaultLocation: 1,
    NoDefaultLocation: 2,
}

export const JobTemplateType = {
    Default: 0,
    Custom: 1,
}

export const CustomPhotoSignature = {
    No: 0,
    Yes: 1,
    Optional: 2,
}

export const MessageType = {
    Admin: 0,
    Driver: 1,
    Comment: 2,
}

export const SubscriptionPaymentType = {
    Paypal: 0,
    Cash: 1,
    Cheque: 2,
    Stripe: 3,
}

export const Operators = {
    OR: 1,
    AND: 2,
};

export const AuditTrailObjectType = {
    Customer: 0,
    CustomerSite: 1,
    Service: 2,
    ServiceCharge: 3,
    AdditionalServiceCharge: 4,
    JobTemplate: 5,
    RecurringJob: 6,
    AccountBillingProfile: 7,
    BinType: 8,
    WasteType: 9,
    BinNumber: 10,
    Driver: 11,
    Vehicle: 12,
    Payroll: 13,
    AccountReminder: 14,
    AccountJobWorkingTime: 15,
    Job: 16,
    LocalGlobalService: 17,
    DriverDetailsMonthly: 19,
};
export const AuditTrailObjectTypeText = [
    'Customer',
    'Customer Site',
    'Service',
    'Standard Service',
    'Additional Service',
    'Job Template',
    'Recurring Job',
    'Billing Profile',
    'Bin Type',
    'Waste Type',
    'Bin Number',
    'Driver',
    'Vehicle',
    'Payroll',
    'Reminder',
    'Job Working Time',
    'Job',
    '',
    '',
    'Driver Details Monhly',
];

export const ReportUOM = {
    tons: 0,
    kg: 1,
};

export const WeighChitLayout = {
    Default: 0,
    Receipt: 1,
};

export const AccountCustomFieldType = {
    WeighChit: 0,
};

export const QuickbookOrXero = {
    None: 0,
    Quickbook: 1,
    Xero: 2,
};

export const XeroPaymentTermType = {
    DAYS_AFTER_BILL_DATE: '0',
    DAYS_AFTER_BILL_MONTH: '1',
    OF_CURRENT_MONTH: '2',
    OF_FOLLOWING_MONTH: '3',
};
export const XeroPaymentTermTypeItems = [
    {
        value: XeroPaymentTermType.DAYS_AFTER_BILL_DATE,
        title: 'day(s) after bill date',
    },
    {
        value: XeroPaymentTermType.DAYS_AFTER_BILL_MONTH,
        title: 'day(s) after bill month',
    },
    {
        value: XeroPaymentTermType.OF_CURRENT_MONTH,
        title: 'of the current month',
    },
    {
        value: XeroPaymentTermType.OF_FOLLOWING_MONTH,
        title: 'of the following month',
    }
];

export const PayrollTemplateWorkingDays = {
    Weekdays: 0,
    Weekends: 1,
    PublicHolidays: 2,
};

export const Payroll = {
    Distance: 0,
    TripCount: 1,
};

export const PayrollTemplateVariableType = {
    WorkTime: 0,
    WorkTimeDistance: 1,
    WorkTimeJobType: 2,
    WorkTimeDistanceJobType: 3,
    WorkTimeGeofenceBeta: 4,
    WorkTimeGeofenceJobTypeBeta: 5,
    WorkTimeBeta: 6,
    WorkTimeJobTypeBeta: 7,
    None: 8,
};
export const PayrollTemplateIncentiveType = {
    None: 0,
    StandardWage: 1,
    PackageWage: 2,
    GraduatedWage: 3,
    VolumeWage: 4,
};

export const ReminderStatus = {
    Open: 0,
    Closed: 1,
};

export const BillingStatusParams = {
    All: {
        isBillable: null,
        isInvoiced: null,
        isPaid: null,
        isPurchase: null,
        isExpensed: null,
    },
    Billable: {
        isBillable: true,
        isInvoiced: null,
        isPaid: null,
        isPurchase: null,
        isExpensed: null,
    },
    NonBillable: {
        isBillable: false,
        isInvoiced: null,
        isPaid: null,
        isPurchase: null,
        isExpensed: null,
    },
    Unbilled: {
        isBillable: true,
        isInvoiced: false,
        isPaid: false,
        isPurchase: null,
        isExpensed: null,
    },
    Billed: {
        isBillable: true,
        isInvoiced: true,
        isPaid: false,
        isPurchase: null,
        isExpensed: null,
    },
    NonBillablePurchase: {
        isBillable: false,
        isInvoiced: null,
        isPaid: null,
        isPurchase: null,
        isExpensed: null,
    },
    UnbilledPurchase: {
        isBillable: true,
        isInvoiced: null,
        isPaid: false,
        isPurchase: false,
        isExpensed: null,
    },
    BilledPurchase: {
        isBillable: true,
        isInvoiced: null,
        isPaid: false,
        isPurchase: true,
        isExpensed: null,
    },
    NonBillableExpense: {
        isBillable: false,
        isInvoiced: null,
        isPaid: null,
        isPurchase: null,
        isExpensed: null,
    },
    UnbilledExpense: {
        isBillable: true,
        isInvoiced: null,
        isPaid: false,
        isPurchase: false,
        isExpensed: false,
    },
    BilledExpense: {
        isBillable: true,
        isInvoiced: null,
        isPaid: false,
        isPurchase: true,
        isExpensed: true,
    },
    Paid: {
        isBillable: true,
        isInvoiced: null,
        isPaid: true,
        isPurchase: null,
        isExpensed: null,
    },
    isPurchase: {
        isBillable: null,
        isInvoiced: null,
        isPaid: null,
        isPurchase: true,
        isExpensed: null,
    },
    isNotPurchase: {
        isBillable: null,
        isInvoiced: null,
        isPaid: null,
        isPurchase: false,
        isExpensed: null,
    },
    isExpensed: {
        isBillable: null,
        isInvoiced: null,
        isPaid: null,
        isPurchase: null,
        isExpensed: true,
    },
    isNotExpense: {
        isBillable: null,
        isInvoiced: null,
        isPaid: null,
        isPurchase: null,
        isExpensed: false,
    },
};
export const BillingStatus = {
    NonBillable: 0,
    Unbilled: 1,
    MarkAsBilled: 2,
    Billed: 3,
    MarkAsPaid: 4,
    Paid: 5,
};

export const AccountSettingType = {
    DefaultMapJobType: 0,
    DefaultMapPositionIsoCode: 1,
    DefaultMapPositionCountryName: 2,
    DefaultMapPositionLatitude: 3,
    DefaultMapPositionLongitude: 4,
    DefaultMapPositionZoom: 5,
    CurrencyCode: 6,
    CurrencyCodeValue: 7,
    AllowDefaultUomChange: 8,
    AllowDriverMultipleJobs: 9
};

export const UserCookieSettingType = {
    Customer: 0,
    CustomerStandardService: 1,
    CustomerAdditionalService: 2,
    CustomerSite: 3,
    CustomerSiteStandardService: 4,
    CustomerSiteAdditionalService: 5,
    Job: 6,
    BillingBySite: 7,
    BillingByCustomer: 8,
    BillingByJobDates: 9,
    Reminders: 10,
    Alert: 11,
    CustomerServices: 12,
    BillingBySiteTable: 13,
    BillingBySitePanel: 14,
    BillingByCustomerDetails: 15,
    Manage: 16,
    AuditTrail: 17,
    GlobalService: 18,
    BillingCategory: 19,
    CustomersEmail: 19,
    BinCenter: 20
};

export const PageCookieSettingType = {
    isCookies: 'is_cookies',
    sortColumn: 'sort_column',
    sortDir: 'sort_dir',
    sort: 'sort',
    currentPage: 'current_page',
    rowsPerPageSelected: 'rows_per_page_selected',
    fromDate: 'from_date',
    toDate: 'to_date',
    formatRangeDate: 'format_range_date',
    columnVisibility: 'column_visibility',
    columns: 'columns',
    isIncludeInactive: 'is_include_inactive',
    isInactive: 'is_inactive',
    leftIsIncludeInactive: 'left_is_include_inactive',
    leftIsInactive: 'left_is_inactive',
    search: 'search',
    jobStatusFilter: 'job_status_filter',
    jobTemplateFilter: 'job_template_filter',
    isStatusOrTypes: 'is_status_or_types',
    dateRange: 'date_range',
    isExpandedAll: 'is_expanded_all',
    collapseLeft: 'collapse_left',
    selectedCustomer: 'selected_customer',
    customerName: 'customer_name',
    secondaryPaneSize: 'secondary_pane_size',
    selectedDateFilterIndex: 'selected_date_filter_ndex',
    jobBilledUnbilledFilter: 'job_billed_unbilled_filter',
    isDefaultGroupByDate: 'is_default_group_by_date',
    realTimeUpdate: 'real_time_update',
};

export const RolesPermissions = {
    Dashboard: 100,
    
    Customer: 200,
        CustomersSites: 201,
        CustomersServices: 202,
        CustomersOverview: 203,
        Customers: 204,

    Jobs: 300,
        JobsJobForm: 301,
        EditJobAfterCompleted: 305,
        JobsProofOfDelivery: 302,
        JobsAuditTrails: 303,
        JobsJobBilling: 304,

    Billing: 400,
        BillingInvoice: 401,
        BillingPurchase: 402,
        BillingExpense: 404,
        BillingCategory: 403,

    Manage: 500,
        ManageResources: 501,
        ManageJobs: 502,
        ManagePayroll: 503,
        ManageUsers: 504,
        ManageProfile: 505,
        ManageSettings: 506,
        ManageGeofence: 507,

    Reports: 600,
        ReportsBinsOnSite: 601,
        ReportsBinsActivities: 602,
        ReportsCollectionSummary: 603,
        ReportsCollectionDetailed: 604,
        ReportsPurchaseDetailed: 605,
        ReportsDriverTripPay: 606,
        
    Map: 700,

    AuditTrail: 800,

    GlobalService: 900,

    BinCenter: 1000
};

export const PagesPermissions = {
    Dashboard: {
        read: 12,
        write: 12,
    },
    Map: {
        read: 13,
        write: 13,
    },
    Customers: {
        read: 3,
        write: 4,
    },
    CustomerSites: {
        read: 16,
        write: 16,
    },
    CustomerServices: {
        read: 17,
        write: 17,
    },
    CustomersOverview: {
        read: 47,
        write: 47,
    },
    CustomersEmail: {
        read: null,
        write: null,
    },

    Jobs: {
        read: 6,
        write: 7,
    },
        JobDetails: {
            read: 18,
            write: 18,
        },
        EditJobAfterCompleted: {
            read: 53,
            write: 53,
        },
        JobProofOfDelivery: {
            read: 19,
            write: 19,
        },
        JobAuditTrail: {
            read: 20,
            write: 20,
        },
        JobBilling: {
            read: 21,
            write: 21,
        },
    BillingInvoice: {
        read: 10,
        write: 10,
    },
    BillingPurchase: {
        read: 38,
        write: 38,
    },
    BillingExpense: {
        read: 51,
        write: 51,
    },
    BillingCategory: {
        read: 45,
        write: 46,
    },
    Manage: {
        read: 8,
        write: 8,
    },
        ManageDrivers: {
            read: 22,
            write: 22,
        },
        ManageVehicles: {
            read: 30,
            write: 30,
        },
        ManageWaste: {
            read: 23,
            write: 23,
        },
        ManageBins: {
            read: 24,
            write: 24,
        },

        ManageJobtemplate: {
            read: 29,
            write: 29,
        },
        ManageService: {
            read: 25,
            write: 25,
        },
        ManageTime: {
            read: 37,
            write: 37,
        },

        ManagePayroll: {
            read: 31,
            write: 31,
        },
        ManageWorkHours: {
            read: 33,
            write: 33,
        },
        ManageGeofence: {
            read: 50,
            write: 50,
        },

        ManageUsers: {
            read: 1,
            write: 2,
        },

        ManageCompany: {
            read: 26,
            write: 26,
        },
        
        ManageGeneral: {
            read: 28,
            write: 28,
        },
        ManageSubscription: {
            read: 27,
            write: 27,
        },
        ManageHoliday: {
            read: 32,
            write: 32,
        },
    Reports: {
        read: 14,
        write: 14,
    },
        ReportsBins: {
            read: 34,
            write: 34,
        },
        ReportsBinsActivities: {
            read: 39,
            write: 39,
        },
        ReportsCollectionSummary: {
            read: 35,
            write: 35,
        },
        ReportsCollectionDetailed: {
            read: 40,
            write: 40,
        },
        ReportsPurchaseDetailed: {
            read: 41,
            write: 41,
        },
        ReportsDriverTripPay: {
            read: 36,
            write: 36,
        },
    Alert: {
        read: null,
        write: null,
    }, 
    Reminders: {
        read: null,
        write: null,
    },  
    AuditTrail: {
        read: 42,
        write: 42,
    },  
    GlobalService: {
        read: 43,
        write: 44,
    },
    MultipleBillingProfile: {
        read: 48,
        write: 48,
    },
    AllowBillingProfileAccountingIntegration: {
        read: 49,
        write: 49,
    },

    BinCenter: {
        read: 52,
        write: 52,
    },
};

export const PagesToRolesPermissions = {
    [PagesPermissions.Dashboard.read]: [RolesPermissions.Dashboard],

    [PagesPermissions.Map.read]: [RolesPermissions.Map],

    [PagesPermissions.Customers.read]: [RolesPermissions.Customers],
    [PagesPermissions.CustomerSites.read]: [RolesPermissions.CustomersSites],
    [PagesPermissions.CustomerServices.read]: [RolesPermissions.CustomersServices],
    [PagesPermissions.CustomersOverview.read]: [RolesPermissions.CustomersOverview],


    [PagesPermissions.Jobs.read]: [],
        [PagesPermissions.JobDetails.read]: [RolesPermissions.JobsJobForm],
        [PagesPermissions.EditJobAfterCompleted.read]: [RolesPermissions.EditJobAfterCompleted],
        [PagesPermissions.JobProofOfDelivery.read]: [RolesPermissions.JobsProofOfDelivery],
        [PagesPermissions.JobAuditTrail.read]: [RolesPermissions.JobsAuditTrails],
        [PagesPermissions.JobBilling.read]: [RolesPermissions.JobsJobBilling],


    [PagesPermissions.BillingInvoice.read]: [RolesPermissions.BillingInvoice],
    [PagesPermissions.BillingPurchase.read]: [RolesPermissions.BillingPurchase],
    [PagesPermissions.BillingExpense.read]: [RolesPermissions.BillingExpense],
    [PagesPermissions.BillingCategory.read]: [RolesPermissions.BillingCategory],
    

    [PagesPermissions.Manage.read]: [],
        [PagesPermissions.ManageDrivers.read]: [RolesPermissions.ManageResources],
        [PagesPermissions.ManageVehicles.read]: [RolesPermissions.ManageResources],
        [PagesPermissions.ManageWaste.read]: [RolesPermissions.ManageResources],
        [PagesPermissions.ManageBins.read]: [RolesPermissions.ManageResources],

        [PagesPermissions.ManageJobtemplate.read]: [RolesPermissions.ManageJobs],
        [PagesPermissions.ManageService.read]: [RolesPermissions.ManageJobs],
        [PagesPermissions.ManageTime.read]: [RolesPermissions.ManageJobs],

        [PagesPermissions.ManagePayroll.read]: [RolesPermissions.ManagePayroll],
        [PagesPermissions.ManageWorkHours.read]: [RolesPermissions.ManagePayroll],

        [PagesPermissions.ManageGeofence.read]: [RolesPermissions.ManageGeofence],

        [PagesPermissions.ManageUsers.read]: [RolesPermissions.ManageUsers],

        [PagesPermissions.ManageCompany.read]: [RolesPermissions.ManageProfile],

        [PagesPermissions.ManageGeneral.read]: [RolesPermissions.ManageSettings],
        [PagesPermissions.ManageSubscription.read]: [RolesPermissions.ManageSettings],
        [PagesPermissions.ManageHoliday.read]: [RolesPermissions.ManageSettings],

        
    [PagesPermissions.GlobalService.read]: [RolesPermissions.GlobalService],


    [PagesPermissions.Reports.read]: [],
        [PagesPermissions.ReportsBins.read]: [RolesPermissions.ReportsBinsOnSite],
        [PagesPermissions.ReportsBinsActivities.read]: [RolesPermissions.ReportsBinsActivities],
        [PagesPermissions.ReportsCollectionSummary.read]: [RolesPermissions.ReportsCollectionSummary],
        [PagesPermissions.ReportsCollectionDetailed.read]: [RolesPermissions.ReportsCollectionDetailed],
        [PagesPermissions.ReportsPurchaseDetailed.read]: [RolesPermissions.ReportsPurchaseDetailed],
        [PagesPermissions.ReportsDriverTripPay.read]: [RolesPermissions.ReportsDriverTripPay],

    
    [PagesPermissions.AuditTrail.read]: [RolesPermissions.AuditTrail],

    [PagesPermissions.BinCenter.read]: [RolesPermissions.BinCenter],
};
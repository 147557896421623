import React, { Component } from 'react';

import queryString from 'query-string';

import { 
  JobStepType, 
  StepType,
  DefaultLoaction,
  JobTemplateType,
} from '../../../utils/enums';
import apiUtil from '../../../api/apiUtil.jsx';
import Api from '../../../api/OLD/Dispatch/JobTemplate.jsx';
import JobStatusApi from '../../../api/OLD/Dispatch/JobStatus.jsx';

import { 
    Box,
} from '@material-ui/core';

import JobTypeLayout from "./Layout/JobTypeLayout.jsx";

import ManageLabel from '../../../components/OLD/input/ManageLabel.jsx';
import Snackbar from '../../../components/OLD/Snackbar/Snackbar.jsx';

import TopSection from "./Partial/TopSection.jsx";
import MenuSection from "./Partial/MenuSection.jsx";
import AddressesSection from "./Partial/AddressesSection.jsx";
import JobStepsSection from "./Partial/JobStepsSection.jsx";
import AppPermissionSection from "./Partial/AppPermissionSection.jsx";
import OthersSection from "./Partial/OthersSection.jsx";


class JobTypeForm extends Component {

  constructor(props){
    super(props);

    this.jobTypeLayoutRef = React.createRef();
    this.topSectionRef = React.createRef();
    this.menuSectionRef = React.createRef();
    this.addressesSectionRef = React.createRef();
    this.jobStepsSectionRef = React.createRef();
    this.appPermissionSectionRef = React.createRef();
    this.othersSectionRef = React.createRef();

    this.isDialog = ((props.isDialog === true || props.isDialog === false) ? props.isDialog : false);
    this.id = null;
    this.isCopy = false;
    
    if(this.isDialog){
      this.id = (props.id && props.id > 0) ? props.id : null;
      this.isCopy = ((props.isCopy === true || props.isCopy === false) ? props.isCopy : false);
    } else {
      let q = queryString.parse(window.location.search);
      
      this.id = (q.id && q.id !== '') ? parseInt(q.id) : null;
      this.isCopy = (q.isCopy === 'true') ? true : false;
    }

    this.state = this.getInitState();
  }


  /* STATE */
	getInitState = (field = '') => {
    let data = {
      isLoading: false,
      disabled: false,

      jobStatus: [],

      jobType: 0,
      name: '',
      desc: '',

      jobTemplateType: JobTemplateType.Custom,
      isEditableWasteTypeIn: false,
    }

    return data;
  }

	resetState = (field = '', callback = null) => {
		this.setState(this.getInitState(field), () => {
			if(callback !== null){
				callback();
			}
		});
	}
  /* END STATE */


  componentDidMount() {
    if(this.id > 0){
      this.callDetailsApi();
    } else {
      this.callJobStatusReadApi((data) => {
        this.setState({
          jobStatus: data
        });
      });
    }
  }


  /* API */
  callJobStatusReadApi = (callback = null) => {
    const { token } = this.context;
    
    JobStatusApi.read(
      {
        currentPage: 1,
        pageSize: apiUtil.getMaxPageSize(),
        searchQuery: ''
      }, 
      token
    ).then(result => {
      apiUtil.parseResult(result, (data) => {
        let status = apiUtil.sortStatus(data.data);
        
        if(callback !== null){
          callback(status);
        }
      }, (error, type) => {
        if(callback !== null){
          callback([]);
        }
      });
    });
  }

  callDetailsApi = () => {
    const { token } = this.context;
    
    this.setState({
      isLoading: true,
    });

    Api.details(
      this.id, 
      token
    ).then(result => {
      apiUtil.parseResult(result, (data) => {
        let templateStatus = [];
        if(data.templateStatuses && data.templateStatuses.length > 0){
          for(let i = 0; i < data.templateStatuses.length; i++){
            templateStatus.push(data.templateStatuses[i].templateStatus)
          }
        }

        
        let jobTypeInt = 0;
        try {
          if(data && data.templateSteps && data.templateSteps.length > 0){
            jobTypeInt = parseInt(data.templateSteps[0].stepType);
          }
        } catch(e){}

        
        this.setState({
          jobStatus: templateStatus,

          jobType: jobTypeInt,
          name: data.jobTemplateName,
          desc: data.jobTemplateDescription,
          jobTemplateType: data.jobTemplateType,

          isEditableWasteTypeIn: data.isEditableWasteTypeIn,
        }, () => {
          setTimeout(() => {
            if(this.topSectionRef && this.topSectionRef.current){
              // this.topSectionRef.current.setJobType(jobTypeInt);
              this.topSectionRef.current.setName(data.jobTemplateName);
              this.topSectionRef.current.setDesc(data.jobTemplateDescription);
            }
    
            let stepsStruct = apiUtil.getStepStruct(jobTypeInt);
            let templateSteps = data.templateSteps.sort((a,b) => a.stepOrder - b.stepOrder);
        
            if(stepsStruct && stepsStruct.length > 0){
              let count = 0;
              stepsStruct.forEach((struct, i) => {
                let index = (i + 1);
  
                if(struct.jobType !== JobStepType.Shift){
                  if((struct.stepTypeDraw === StepType.BIN_IN) || (struct.stepTypeDraw === StepType.BIN_OUT)){
                    count++;
                    if(this.addressesSectionRef && this.addressesSectionRef.current){
                      let hasDefaultSite = (templateSteps && templateSteps[i]) ? templateSteps[i].hasDefaultSite : false;
                      let location = this.addressesSectionRef.current.getSelectedLocation(hasDefaultSite);
                      this.addressesSectionRef.current.setLocation(location, count);
    
                      let templateStepSites = (templateSteps && templateSteps[i] && templateSteps[i].templateStepSites && templateSteps[i].templateStepSites.length > 0) ? templateSteps[i].templateStepSites : [{
                        stepSiteName: '', 
                        stepSiteAddress: '', 
                        stepSiteLatitude: '', 
                        stepSiteLongitude: '',
                        isDefault: true,
                      }];
                      let isDefault = false;
                      if(templateStepSites && templateStepSites.length > 0){
                        isDefault = templateStepSites.some(x => x.isDefault === true);
                      }
                      if(!isDefault){
                        templateStepSites[0].isDefault = true;
                      }

                      this.addressesSectionRef.current.setAddress((location === DefaultLoaction.DefaultLocation) ? templateStepSites : [], count);
                      
                      if(this.addressesSectionRef && this.addressesSectionRef.current && this.addressesSectionRef.current['gridTable' + count] && this.addressesSectionRef.current['gridTable' + count].current){
                        this.addressesSectionRef.current['gridTable' + count].current.clearEmptyRow();
                      }
                    }
                  }
                }
  
                
                if(this.jobStepsSectionRef && this.jobStepsSectionRef.current){
                  if(struct.jobType === JobStepType.Pull){
                    if(index === 1){
                      this.jobStepsSectionRef.current.setIsRequireBinNumberToEnd2(templateSteps[i].isRequireBinNumberToEnd);
          
                      this.jobStepsSectionRef.current.setNumberofPhotosRequired1(templateSteps[i].numberofPhotosRequired);
                      this.jobStepsSectionRef.current.setMustTakePhoto1(templateSteps[i].mustTakePhoto);
                      this.jobStepsSectionRef.current.setMustTakeSignature(templateSteps[i].mustTakeSignature);
                      // this.jobStepsSectionRef.current.setIsRequirePaymentCollection(templateSteps[i].isRequirePaymentCollection);
                    }
          
                    if(index === 2){
                      this.jobStepsSectionRef.current.setNumberofPhotosRequired2(templateSteps[i].numberofPhotosRequired);
                      this.jobStepsSectionRef.current.setMustTakePhoto2(templateSteps[i].mustTakePhoto);
            
                      this.jobStepsSectionRef.current.setIsRequireBinWeight2(templateSteps[i].isRequireBinWeight);
                      this.jobStepsSectionRef.current.setIsRequireReviewWasteType((!data.isEditableWasteTypeIn) ? false : templateSteps[i].isRequireReviewWasteType);
                    }
  
                  } else if(struct.jobType === JobStepType.Put){
                    if(index === 1){
                      this.jobStepsSectionRef.current.setIsRequireBinNumberToEnd1(templateSteps[i].isRequireBinNumberToEnd);
                    }
          
                    if(index === 2){
                      this.jobStepsSectionRef.current.setNumberofPhotosRequired2(templateSteps[i].numberofPhotosRequired);
                      this.jobStepsSectionRef.current.setMustTakePhoto2(templateSteps[i].mustTakePhoto);

                      this.jobStepsSectionRef.current.setMustTakeSignature(templateSteps[i].mustTakeSignature);
                      // this.jobStepsSectionRef.current.setIsRequirePaymentCollection(templateSteps[i].isRequirePaymentCollection);
                    }
  
                  } else if(struct.jobType === JobStepType.Out){
                    if(index === 1){
                      this.jobStepsSectionRef.current.setIsRequireBinNumberToEnd1(templateSteps[i].isRequireBinNumberToEnd);
                      this.jobStepsSectionRef.current.setIsRequireBinWeight1(templateSteps[i].isRequireBinWeight);
                    }
          
                    if(index === 2){
                      this.jobStepsSectionRef.current.setNumberofPhotosRequired2(templateSteps[i].numberofPhotosRequired);
                      this.jobStepsSectionRef.current.setMustTakePhoto2(templateSteps[i].mustTakePhoto);

                      // this.jobStepsSectionRef.current.setMustTakeSignature(templateSteps[i].mustTakeSignature);
                      // this.jobStepsSectionRef.current.setIsRequirePaymentCollection(templateSteps[i].isRequirePaymentCollection);
                    }
  
                  } else if(struct.jobType === JobStepType.Shift){
                    if(index === 1){
                      this.jobStepsSectionRef.current.setIsRequireBinNumberToEnd2(templateSteps[i].isRequireBinNumberToEnd);

                      this.jobStepsSectionRef.current.setNumberofPhotosRequired1(templateSteps[i].numberofPhotosRequired);
                      this.jobStepsSectionRef.current.setMustTakePhoto1(templateSteps[i].mustTakePhoto);
                      this.jobStepsSectionRef.current.setMustTakeSignature(templateSteps[i].mustTakeSignature);
                      // this.jobStepsSectionRef.current.setIsRequirePaymentCollection(templateSteps[i].isRequirePaymentCollection);
                    }

                    if(index === 2){
                      this.jobStepsSectionRef.current.setNumberofPhotosRequired2(templateSteps[i].numberofPhotosRequired);
                      this.jobStepsSectionRef.current.setMustTakePhoto2(templateSteps[i].mustTakePhoto);
                    }

                  } else {
                    if(jobTypeInt !== JobStepType.Pull){
                      if(index === 1){
                        this.jobStepsSectionRef.current.setIsRequireBinNumberToEnd1(templateSteps[i].isRequireBinNumberToEnd);
                      }
                    }
            
                    if((jobTypeInt !== JobStepType.Put) && (jobTypeInt !== JobStepType.Out)){
                      if((jobTypeInt === JobStepType.Pull) || (jobTypeInt === JobStepType.Exchange)){
                        if(index === 2){
                          this.jobStepsSectionRef.current.setIsRequireBinNumberToEnd2(templateSteps[i].isRequireBinNumberToEnd);
                        }
                      }
            
                      if(index === 2){
                        this.jobStepsSectionRef.current.setNumberofPhotosRequired1(templateSteps[i].numberofPhotosRequired);
                        this.jobStepsSectionRef.current.setMustTakePhoto1(templateSteps[i].mustTakePhoto);
                        this.jobStepsSectionRef.current.setMustTakeSignature(templateSteps[i].mustTakeSignature);
                        // this.jobStepsSectionRef.current.setIsRequirePaymentCollection(templateSteps[i].isRequirePaymentCollection);
                      }
                    }
            
                    if(index === 3){
                      this.jobStepsSectionRef.current.setNumberofPhotosRequired2(templateSteps[i].numberofPhotosRequired);
                      this.jobStepsSectionRef.current.setMustTakePhoto2(templateSteps[i].mustTakePhoto);
            
                      this.jobStepsSectionRef.current.setIsRequireBinWeight2(templateSteps[i].isRequireBinWeight);
                      this.jobStepsSectionRef.current.setIsRequireReviewWasteType((!data.isEditableWasteTypeIn) ? false : templateSteps[i].isRequireReviewWasteType);
                    }
                  }
                }
  
              });
            }
  
  
            if(this.appPermissionSectionRef && this.appPermissionSectionRef.current){
              this.appPermissionSectionRef.current.setIsEditableBinTypeOut(data.isEditableBinTypeOut);
              this.appPermissionSectionRef.current.setIsEditableWasteTypeOut(data.isEditableWasteTypeOut);
              this.appPermissionSectionRef.current.setIsEditableWasteTypeIn(data.isEditableWasteTypeIn);
              this.appPermissionSectionRef.current.setIsEditableBinNumberIn(data.isEditableBinNumberIn);
              this.appPermissionSectionRef.current.setIsEditableBinNumberOut(data.isEditableBinNumberOut);
    

            }
  
  
            if(this.othersSectionRef && this.othersSectionRef.current){
              this.othersSectionRef.current.setIsBillable(data.isBillable);
  
              let jobDurationHours = (data.jobDurationHours && data.jobDurationHours.toString() !== '') ? data.jobDurationHours.toString() : '0';
              let jobDurationMinutes = (data.jobDurationMinutes && data.jobDurationMinutes.toString() !== '') ? data.jobDurationMinutes.toString() : '0';
              let duration = apiUtil.getDuration(jobDurationHours, jobDurationMinutes);
  
              this.othersSectionRef.current.setDuration(duration);
            }

            this.setState({
              isLoading: false,
            });
          }, 300);
        });
      }, (error, type) => {
        this.closeForm();
      });
    });
  }

  callCreateApi = (data, callback = null) => {
		const { token } = this.context;
		
		this.setState({
			isLoading: true
		});

		Api.create(
			data, 
			token
		).then(result => {
			apiUtil.parseResult(result, (data) => {
				this.setState({
          isLoading: false,
          showError: true,
          errorText: "Successfully created!",
          errorSeverity: 'success',
				}, () => {
					if(callback !== null){
						callback(data);
					}
				});
			}, (error, type) => {
				this.setState({
          isLoading: false,
          showError: true,
          errorText: error,
          errorSeverity: type
				});
			});
		});
	}

  callCopyApi = (row, callback = null) => {
		const { token } = this.context;
		
		this.setState({
			isLoading: true
		});

		row.isCopy = true;
		row.jobTemplateType = JobTemplateType.Custom;

		delete row.accountId;
		delete row.jobTemplateId;
		delete row.jobPaymentTypeList;

		if(row.templateSteps && row.templateSteps.length > 0){
			for(let i = 0; i < row.templateSteps.length; i++) {
				delete row.templateSteps[i].jobTemplateStepId;
				delete row.templateSteps[i].jobTemplateId;
				delete row.templateSteps[i].binType;
				delete row.templateSteps[i].wasteType;
				delete row.templateSteps[i].site;
				delete row.templateSteps[i].templateTimeBlocks;

				if(row.templateSteps[i].templateStepSites && row.templateSteps[i].templateStepSites.length > 0){
					for(let j = 0; j < row.templateSteps[i].templateStepSites.length; j++) {
						delete row.templateSteps[i].templateStepSites[j].jobTemplateStepId;
						delete row.templateSteps[i].templateStepSites[j].jobTemplateStepSiteId;
					}
				}
			}
		}

		// let templateStatuses = [];
		// if(row.templateStatuses && row.templateStatuses.length > 0){
		// 	for(let i = 0; i < row.templateStatuses.length; i++) {
		// 		templateStatuses.push(row.templateStatuses[i].templateStatus);
		// 	}
		// }
		// row.templateStatuses = templateStatuses;

		Api.create(
			row,
			token
		).then(result => {
			apiUtil.parseResult(result, (data) => {
				this.setState({
					showError: true,
					errorText: "Successfully copied!",
					errorSeverity: 'success',
					isLoading: false,
				}, () => {
					if(callback !== null){
						callback(true);
					}
				});
			}, (error, type) => {
				this.setState({
					showError: true,
					errorText: error,
					errorSeverity: type,
					isLoading: false,
				}, () => {
					if(callback !== null){
						callback(false);
					}
				});
			});
		});
	}

	callUpdateApi = (data, callback = null) => {
		const { token } = this.context;
		
		this.setState({
			isLoading: true
		});

		Api.update(
			data, 
			token
		).then(result => {
			apiUtil.parseResult(result, (data) => {
				this.setState({
          isLoading: false,
          showError: true,
          errorText: "Successfully updated!",
          errorSeverity: 'success',
				}, () => {
					if(callback !== null){
						callback(data);
					}
				});
			}, (error, type) => {
				this.setState({
          isLoading: false,
          showError: true,
          errorText: error,
          errorSeverity: type
				});
			});
		});
	}
  /* END API */


  topSection = () => {
    return <TopSection 
      ref={this.topSectionRef}
      id={this.id}
      isCopy={this.isCopy}
      isLoading={this.state.isLoading}
      disabled={this.state.disabled}
      jobType={this.state.jobType}
      
      // onChangeJobType={(item) => {
      //   this.setState({
      //     jobType: item,
      //   });
      // }}
      onChangeName={(value) => {
        this.setState({
          name: value,
        });
      }}
      onChangeDesc={(value) => {
        this.setState({
          desc: value,
        });
      }}

      onClose={() => {
        this.closeForm();
      }}
    />
  }
  menuSection = () => {
    return <MenuSection 
      ref={this.menuSectionRef}
      id={this.id}
      isCopy={this.isCopy}
      isLoading={this.state.isLoading}
      disabled={this.state.disabled}
      jobType={this.state.jobType}
    />
  }

  addressesSection = () => {
    return <Box>
        <ManageLabel
            className={'no-margin'}
            label={'Addresses'}
            weight={'bold'}
            size={'md'}
        />
        <Box pt={1}>
            <AddressesSection 
                ref={this.addressesSectionRef}
                id={this.id}
                isCopy={this.isCopy}
                isLoading={this.state.isLoading}
                disabled={this.state.disabled}
                jobType={this.state.jobType}
            />
        </Box>
    </Box>
  }

  jobStepsSection = () => {
    return <Box>
        <ManageLabel
            className={'no-margin'}
            label={'Job Steps'}
            weight={'bold'}
            size={'md'}
        />
        <Box pt={1}>
            <JobStepsSection 
                ref={this.jobStepsSectionRef}
                id={this.id}
                isCopy={this.isCopy}
                isLoading={this.state.isLoading}
                disabled={this.state.disabled}
                jobType={this.state.jobType}
                isEditableWasteTypeIn={this.state.isEditableWasteTypeIn}
            />
        </Box>
    </Box>
  }

  appPermissionSection = () => {
    return <Box>
        <ManageLabel
            className={'no-margin'}
            label={'App Permission'}
            weight={'bold'}
            size={'md'}
        />
        <Box pt={1}>
            <AppPermissionSection 
                ref={this.appPermissionSectionRef}
                id={this.id}
                isCopy={this.isCopy}
                isLoading={this.state.isLoading}
                disabled={this.state.disabled}
                jobType={this.state.jobType}
                onChangIsEditableWasteTypeIn={(checked) => {
                    this.setState({
                        isEditableWasteTypeIn: checked
                    });
                }}
            />
        </Box>
    </Box>
  }

  othersSection = () => {
    return <Box>
        <ManageLabel
            className={'no-margin'}
            label={'Others'}
            weight={'bold'}
            size={'md'}
        />
        <Box pt={1}>
            <OthersSection 
                ref={this.othersSectionRef}
                id={this.id}
                isCopy={this.isCopy}
                isLoading={this.state.isLoading}
                disabled={this.state.disabled}
                jobType={this.state.jobType}
            />
        </Box>
    </Box>
  }
  

  /***** SUBMIT *****/
  closeForm = () => {
      if(this.isDialog){
          if(this.props.onClose){
              this.props.onClose();
          }
      } else {
          this.props.history.push('/manage/job/job-types');
      }
  }

  onSubmit = async (e) => {
    this.setState({
      isLoading: true,
    });


    let data = await this.handleSave();


    if(data && data.stepType && data.stepType === ''){
      this.setState({
        showError: true,
        errorText: "Please select job type!",
        errorSeverity: 'error',
        isLoading: false
      });
      return;
    }


    if(data && data.jobTemplateName === ''){
      this.setState({
        showError: true,
        errorText: "Please enter template name!",
        errorSeverity: 'error',
        isLoading: false
      });
      return;
    }

    
    let jobType = 0;
    try {
      jobType = parseInt(this.state.jobType)
    } catch(e){}

    let stepsStruct = apiUtil.getStepStruct(jobType);


    if(jobType !== JobStepType.Shift){
      if(this.addressesSectionRef && this.addressesSectionRef.current){
        
        if(this.addressesSectionRef.current.getLocation(1) === DefaultLoaction.DefaultLocation){
          if(this.addressesSectionRef.current.gridTable1 && this.addressesSectionRef.current.gridTable1.current){
            // if(this.addressesSectionRef.current.gridTable1.current.hasEmpty()){
            //   this.setState({
            //     showError: true,
            //     errorText: "You must enter addresses!",
            //     errorSeverity: 'error',
            //     isLoading: false
            //   });
            //   return;
            // }

            let coordItem = null;
            let items = this.addressesSectionRef.current.gridTable1.current.getItems();
            if(items && items.length > 0){
              for(let i = 0; i < items.length; i++){
                let item = items[i];
                if(item.stepSiteLatitude === null || item.stepSiteLongitude === null){
                  coordItem = item;
                  break;
                }
              }
            }

            if(coordItem !== null){
              this.setState({
                showError: true,
                errorText: "You must select an address from the autosuggest list for the " + coordItem.stepSiteAddress + " address!",
                errorSeverity: 'error',
                isLoading: false
              });
              return;
            }
          }

          let isDefaultExistInAddresses1 = this.addressesSectionRef.current.isDefaultExistInAddresses(1);
          if(!isDefaultExistInAddresses1){
            this.setState({
              showError: true,
              errorText: "You must check the default address!",
              errorSeverity: 'error',
              isLoading: false
            });
            return;
          }
        }


        if(this.addressesSectionRef.current.getLocation(2) === DefaultLoaction.DefaultLocation){
          if(this.addressesSectionRef.current.gridTable2 && this.addressesSectionRef.current.gridTable2.current){
            // if(this.addressesSectionRef.current.gridTable2.current.hasEmpty()){
            //   this.setState({
            //     showError: true,
            //     errorText: "You must enter addresses!",
            //     errorSeverity: 'error',
            //     isLoading: false
            //   });
            //   return;
            // }

            let coordItem = null;
            let items = this.addressesSectionRef.current.gridTable2.current.getItems();
            if(items && items.length > 0){
              for(let i = 0; i < items.length; i++){
                let item = items[i];
                if(item.stepSiteLatitude === null || item.stepSiteLongitude === null){
                  coordItem = item;
                  break;
                }
              }
            }

            if(coordItem !== null){
              this.setState({
                showError: true,
                errorText: "You must select an address from the autosuggest list for the " + coordItem.stepSiteAddress + " address!",
                errorSeverity: 'error',
                isLoading: false
              });
              return;
            }
          }
    
          let isDefaultExistInAddresses2 = this.addressesSectionRef.current.isDefaultExistInAddresses(2);
          if(!isDefaultExistInAddresses2){
            this.setState({
              showError: true,
              errorText: "You must check the default address!",
              errorSeverity: 'error',
              isLoading: false
            });
            return;
          }
        }
      }
    }


    if(jobType !== JobStepType.Shift){
      if(data && data.templateSteps && data.templateSteps.length > 0){
        if(stepsStruct && stepsStruct.length > 0){
          let state = false;
          let count = 0;
          stepsStruct.forEach((item, i) => {
              if((item.stepTypeDraw !== StepType.BIN_EXCHANGE) && (item.stepTypeDraw !== StepType.WASTE_COLLECTION)){
                count++;
                let step = data.templateSteps[i];
                
                if(step && step.hasDefaultSite && step.templateStepSites.length === 0){
                  state = true;
                  this.addressesSectionRef.current.addEmptyRow(count)
                }
              }
          });

          if(state){
            this.setState({
              showError: true,
              errorText: "Please add step address!",
              errorSeverity: 'error',
              isLoading: false
            });
            return;
          }
        }
      }
    }


    if(this.isDialog){
      if(this.props.onOk){
        this.props.onOk(
          ((this.id > 0) ? true : false),
          data
        )
      }
    } else {
      if(this.isCopy){
        let data = this.handleSave();
        this.callCopyApi(data, (state) => {
          this.setState({
            isLoading: false
          });

          if(state){
            this.closeForm();
          }
        });
      } else {
        if(this.id > 0){
          this.callUpdateApi(data, (state) => {
            this.setState({
              isLoading: false
            });

            if(state){
              this.closeForm();
            }
          });
        } else {
          this.callCreateApi(data, (state) => {
            this.setState({
              isLoading: false
            });

            if(state){
              this.closeForm();
            }
          });
        }
      }
    }
  }

  handleSave = () => {
    let jobType = 0;
    try {
      jobType = parseInt(this.state.jobType)
    } catch(e){}


    let stepsStruct = apiUtil.getStepStruct(jobType);


    let stepsArray = [];
    if(stepsStruct && stepsStruct.length > 0){
      let count = 0;
      stepsArray = stepsStruct.map((struct, i) => {
        let index = (i + 1);


        let data = {
          stepName: struct.stepName,
          stepTitle: struct.stepTitle,
          stepDescription: struct.stepDescription,
          binCount: struct.binCount,
          stepType: jobType,
          stepOrder: index,

          templateStepSites: [],

          isRequireBinType: false,

          mustTakePhoto: false,
          numberofPhotosRequired: 0,

          mustTakeSignature: false,
          // isRequirePaymentCollection: false,

          isRequireBinWeight: false,
          isRequireReviewWasteType: false,

          isSpecificTime: true,
          isNoTimeRequired: false,
          isTimeBlock: false,
        };


        if((struct.stepTypeDraw === StepType.BIN_IN) || (struct.stepTypeDraw === StepType.BIN_OUT)){
          count++;
          
          if(this.addressesSectionRef && this.addressesSectionRef.current){
            let templateStepSites = this.addressesSectionRef.current.getAddress(count);
            templateStepSites = apiUtil.removeEmptyRows(templateStepSites, [ 'stepSiteName', 'stepSiteAddress' ]);
            this.addressesSectionRef.current.setAddress(templateStepSites, count, () => {
              data['templateStepSites'] = templateStepSites;
            });

            let location = this.addressesSectionRef.current.getLocation(count);
            if(location === DefaultLoaction.DefaultLocation){
              data['hasDefaultSite'] = true;
              data['hasCustomerSite'] = false;
            } else {
              data['hasDefaultSite'] = false;
              data['hasCustomerSite'] = false;
            }
          }
        } else {
          data['hasDefaultSite'] = false;
          data['hasCustomerSite'] = true;
        }

        if(this.jobStepsSectionRef && this.jobStepsSectionRef.current){
          if(struct.jobType === JobStepType.Pull){
            if(index === 1){
              data['isRequireBinNumberToEnd'] = this.jobStepsSectionRef.current.getIsRequireBinNumberToEnd2();
              data['isRequireBinNumberToStart'] = this.jobStepsSectionRef.current.getIsRequireBinNumberToEnd2();

              data['numberofPhotosRequired'] = this.jobStepsSectionRef.current.getNumberofPhotosRequired1();
              data['mustTakePhoto'] = this.jobStepsSectionRef.current.getMustTakePhoto1();
              data['mustTakeSignature'] = this.jobStepsSectionRef.current.getMustTakeSignature();
              // data['isRequirePaymentCollection'] = this.jobStepsSectionRef.current.getIsRequirePaymentCollection();

              data['isRequireReviewWasteType'] = true;
            }

            if(index === 2){
              data['numberofPhotosRequired'] = this.jobStepsSectionRef.current.getNumberofPhotosRequired2();
              data['mustTakePhoto'] = this.jobStepsSectionRef.current.getMustTakePhoto2();
    
              data['isRequireBinWeight'] = this.jobStepsSectionRef.current.getIsRequireBinWeight2();
              data['isRequireReviewWasteType'] = this.jobStepsSectionRef.current.getIsRequireReviewWasteType();
            }
          } else if(struct.jobType === JobStepType.Put){
            if(index === 1){
              data['isRequireBinNumberToEnd'] = this.jobStepsSectionRef.current.getIsRequireBinNumberToEnd1();
              data['isRequireBinNumberToStart'] = this.jobStepsSectionRef.current.getIsRequireBinNumberToEnd1();

              data['isRequireReviewWasteType'] = true;
            }

            if(index === 2){
              data['numberofPhotosRequired'] = this.jobStepsSectionRef.current.getNumberofPhotosRequired2();
              data['mustTakePhoto'] = this.jobStepsSectionRef.current.getMustTakePhoto2();
              
              data['mustTakeSignature'] = this.jobStepsSectionRef.current.getMustTakeSignature();
              // data['isRequirePaymentCollection'] = this.jobStepsSectionRef.current.getIsRequirePaymentCollection();

              data['isRequireReviewWasteType'] = true;
            }
          } else if(struct.jobType === JobStepType.Out){
            if(index === 1){
              data['isRequireBinNumberToEnd'] = this.jobStepsSectionRef.current.getIsRequireBinNumberToEnd1();
              data['isRequireBinNumberToStart'] = this.jobStepsSectionRef.current.getIsRequireBinNumberToEnd1();

              data['isRequireBinWeight'] = this.jobStepsSectionRef.current.getIsRequireBinWeight1();
              
              data['isRequireReviewWasteType'] = true;
            }

            if(index === 2){
              data['numberofPhotosRequired'] = this.jobStepsSectionRef.current.getNumberofPhotosRequired2();
              data['mustTakePhoto'] = this.jobStepsSectionRef.current.getMustTakePhoto2();
              
              // data['mustTakeSignature'] = this.jobStepsSectionRef.current.getMustTakeSignature();
              // data['isRequirePaymentCollection'] = this.jobStepsSectionRef.current.getIsRequirePaymentCollection();

              data['isRequireReviewWasteType'] = false;
            }
          } else if(struct.jobType === JobStepType.Shift){
            if(index === 1){
              data['isRequireBinNumberToEnd'] = this.jobStepsSectionRef.current.getIsRequireBinNumberToEnd2();
              data['isRequireBinNumberToStart'] = this.jobStepsSectionRef.current.getIsRequireBinNumberToEnd2();
              
              data['numberofPhotosRequired'] = this.jobStepsSectionRef.current.getNumberofPhotosRequired1();
              data['mustTakePhoto'] = this.jobStepsSectionRef.current.getMustTakePhoto1();
              data['mustTakeSignature'] = this.jobStepsSectionRef.current.getMustTakeSignature();
              // data['isRequirePaymentCollection'] = this.jobStepsSectionRef.current.getIsRequirePaymentCollection();

              data['isRequireReviewWasteType'] = true;
            }

            if(index === 2){
              data['numberofPhotosRequired'] = this.jobStepsSectionRef.current.getNumberofPhotosRequired2();
              data['mustTakePhoto'] = this.jobStepsSectionRef.current.getMustTakePhoto2();
            }
          } else {
            if(jobType !== JobStepType.Pull){
              if(index === 1){
                data['isRequireBinNumberToEnd'] = this.jobStepsSectionRef.current.getIsRequireBinNumberToEnd1();
                data['isRequireBinNumberToStart'] = this.jobStepsSectionRef.current.getIsRequireBinNumberToEnd1();

                data['isRequireReviewWasteType'] = true;
              }
            }
    
            if((jobType !== JobStepType.Put) && (jobType !== JobStepType.Out)){
              if((jobType === JobStepType.Pull) || (jobType === JobStepType.Exchange)){
                if(index === 2){
                  data['isRequireBinNumberToEnd'] = this.jobStepsSectionRef.current.getIsRequireBinNumberToEnd2();
                  data['isRequireBinNumberToStart'] = this.jobStepsSectionRef.current.getIsRequireBinNumberToEnd2();

                  data['isRequireReviewWasteType'] = true;
                }
              }
    
              if(index === 2){
                data['numberofPhotosRequired'] = this.jobStepsSectionRef.current.getNumberofPhotosRequired1();
                data['mustTakePhoto'] = this.jobStepsSectionRef.current.getMustTakePhoto1();
                data['mustTakeSignature'] = this.jobStepsSectionRef.current.getMustTakeSignature();
                // data['isRequirePaymentCollection'] = this.jobStepsSectionRef.current.getIsRequirePaymentCollection();

                data['isRequireReviewWasteType'] = true;
              }
            }
    
            if(index === 3){
              data['numberofPhotosRequired'] = this.jobStepsSectionRef.current.getNumberofPhotosRequired2();
              data['mustTakePhoto'] = this.jobStepsSectionRef.current.getMustTakePhoto2();
    
              data['isRequireBinWeight'] = this.jobStepsSectionRef.current.getIsRequireBinWeight2();
              data['isRequireReviewWasteType'] = this.jobStepsSectionRef.current.getIsRequireReviewWasteType();
            }
          }
        }


        return data;
      });
    }
    let steps = stepsArray.sort((a,b) => a.stepOrder - b.stepOrder);
    

    let jobTemplateName = '';
    let jobTemplateDescription = '';
    if(this.topSectionRef && this.topSectionRef.current){
      jobTemplateName = this.topSectionRef.current.getName();
      jobTemplateDescription = this.topSectionRef.current.getDesc();
    }


    let isEditableBinTypeOut = false;
    let isEditableWasteTypeOut = false;
    let isEditableWasteTypeIn = false;
    let isEditableBinNumberIn= false;
    let isEditableBinNumberOut= false;

    if(this.appPermissionSectionRef && this.appPermissionSectionRef.current){
      isEditableBinTypeOut = this.appPermissionSectionRef.current.getIsEditableBinTypeOut();
      isEditableWasteTypeOut = this.appPermissionSectionRef.current.getIsEditableWasteTypeOut();
      isEditableWasteTypeIn = this.appPermissionSectionRef.current.getIsEditableWasteTypeIn();
      isEditableBinNumberIn =this.appPermissionSectionRef.current.getIsEditableBinNumberIn();
      isEditableBinNumberOut =this.appPermissionSectionRef.current.getIsEditableBinNumberOut();


    }


    let isBillable = false;
    let durationHours = '';
    let durationMinutes = '';
    if(this.othersSectionRef && this.othersSectionRef.current){
      isBillable = this.othersSectionRef.current.getIsBillable();
      let duration = this.othersSectionRef.current.getDuration();

      let durationItem = apiUtil.getHoursMinutes((duration && duration !== '') ? duration : 2);
      durationHours = durationItem.hours;
      durationMinutes = durationItem.minutes;
    }


    let data = {
      jobTemplateName: jobTemplateName,
      jobTemplateDescription: jobTemplateDescription,
      jobTemplateType: this.state.jobTemplateType,
      isActive: true,
      
      isEditableBinTypeOut: isEditableBinTypeOut,
      isEditableWasteTypeOut: isEditableWasteTypeOut,
      isEditableWasteTypeIn: isEditableWasteTypeIn,
      isEditableBinNumberIn: isEditableBinNumberIn,
      isEditableBinNumberOut: isEditableBinNumberOut,


      isBillable: isBillable,
      jobDurationHours: (durationHours) ? durationHours.toString() : '', 
      jobDurationMinutes: (durationMinutes) ? durationMinutes.toString() : '', 

      templateSteps: steps,
      templateStatuses: this.state.jobStatus,
      
      IsAllowDriverEditOnApp: true,
      IsAssignJobToDriverPhone: true,
    };
    
    if(this.id && this.id > 0){
      data['jobTemplateId'] = this.id;
    }

    return data;
  }
  /***** END SUBMIT *****/


  setSnackBar = () => {
    return <Snackbar 
      open={this.state.showError} 
      text={this.state.errorText}
      severity={this.state.errorSeverity}
      onClose={() => {
        this.setState({
          showError: false,
          errorText: '',
          errorSeverity: null
        });
      }}
    />
  }


  render() {
    return <React.Fragment>
      <JobTypeLayout
        ref={this.jobTypeLayoutRef}
        isDialog={this.isDialog}
        isCopy={this.isCopy}
        isLoading={this.state.isLoading}
        disabled={this.state.disabled}
        jobType={this.state.jobType}

        top={this.topSection()}
        menu={this.menuSection()}

        addresses={this.addressesSection()}
        jobSteps={this.jobStepsSection()}
        appPermission={this.appPermissionSection()}
        others={this.othersSection()}

        onSubmit={this.onSubmit}
      />
      {this.setSnackBar()}
    </React.Fragment>
  }
}

export default JobTypeForm;

import React from "react";
import moment from 'moment';
import _ from 'lodash';
import CountUp from 'react-countup';

import {
    Row,
    Col,
    Button,
    ButtonGroup,
    Dropdown,
    Spinner,
} from "react-bootstrap-v5";

import {
  TableRow,
  TableCell,
} from '@material-ui/core';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Utils from "../../utils/utils";
import { BillingStatus, BillingStatusParams, BillingTypes, UserCookieSettingType } from "../../utils/enums";
import { StyledArrowButton, StyledDatePicker, StyledButtonToday, StyledDropdownHeader, StyledFormCheckItem, StyledDropdownIconButtonMenu, StyledDropDownTitle, StyledRoundedFormCheck, StyledDropdownA, StyledProgressBar, StyledProgressBarChild, StyledStatusTitle, StyledBilllingStatus } from "../../utils/styles";

import { DrawerLayout } from '../../components/drawer/DrawerLayout'
import WrappedButton from '../../components/Buttons/WrappedButton';
import DropdownToggle from '../../components/dropdown/DropdownToggle';
import makeDropdownList from "../../components/dropdown/makeDropdownList";
import DataTableActionMenu from '../../components/OLD/DataTable/DataTableActionMenu';
import DataTable from "../../components/OLD/DataTable/DataTable";
import DataTableAjaxPagination from '../../components/OLD/DataTable/Pagination/DataTableAjaxPaginationV5';
import DataTableScrollSync from '../../components/OLD/DataTable/DataTableScrollSync.jsx';
import IconButtonSearch from '../../components/input/IconButtonSearch';
import RSuiteDateRangePickerCostume from "../../components/OLD/Datepicker/RSuiteDateRangePickerCostume";
import Tooltip from '../../components/Tooltip/Tooltip';
import IconButtonTooltip from "../../components/Buttons/IconButtonTooltip";

import ViewLayout from "./layout/ViewLayout.jsx";
import ViewTopSection from "./partial/ViewTopSection";
import GroupByCustomerDetails from "./partial/GroupByCustomerDetails.jsx";
import RowDrawerForm from "./partial/RowDrawerForm.jsx";

import InvoiceNumberPopup from "./dialog/invoiceNumber/InvoiceNumberPopup";
import InvoiceThisJobPopup from "./dialog/invoiceThisJob/InvoiceThisJobPopup";

import { connect } from 'react-redux'
import checkboxCount from "../../../setup/redux/slices/checkboxCount";
import dateSection from "../../../setup/redux/slices/dateSection";
import tableSection from "../../../setup/redux/slices/tableSection";
import jobStatuses from "../../../setup/redux/slices/jobStatuses";
import billingDefault from "./redux/billingDefault";
import billingInvoiceStatusSection from "./redux/billingInvoiceStatusSection";
import billingQuickbookXero from "./redux/billingQuickbookXero";
import billingBatchAction from "./redux/billingBatchAction";
import billing from "./redux/billing";
import invoiceThisJob from "./redux/invoiceThisJob";
import invoiceNumber from "./redux/invoiceNumber";
import invoiceNumberForm from "./redux/invoiceNumberForm";
import signalRslice from "../../components/signalR/signalRslice";
import cancelToken from "../../../setup/redux/slices/cancelToken";

import { initColumnsInvoice, initColumnVisibilityInvoice, initColumnsExpense, initColumnVisibilityExpense, initColumnsPurchase, initColumnVisibilityPurchase }from './functions/BillingByCustomerColumns';
import { rowFields, rowSchema, initialValues, prepareForm, prepareData, prepareDataForExpenseOUTJobType }from './functions/Fields';


const DropdownMenu = makeDropdownList(Button);


class BillingInvoiceListByCustomer extends React.Component {

    constructor(props) {
        super(props);

        props.layoutRef.current = this;

        let billingType = props.billingType;
        let billingTypeText = ((billingType == BillingTypes.Purchase) ? 'Purchase' : (billingType == BillingTypes.Expense) ? 'Expense' : 'Invoice');

        this.pageName = 'billing_invoice_by_customer_' + billingTypeText;
        this.pageIdField = "jobId";
        this.pageCaption = 'Billing ' + billingTypeText

        this.refRowDrawerForm = React.createRef();
        this.refRowForm = React.createRef();

        this.tableRef = React.createRef();
        this.refDataTableScrollSync = React.createRef();

        this.drawerObjRef = React.createRef();
        this.selectedTableRef = React.createRef();

        this.tableRefs = [];

        this.dispatchUser = (props.auth && props.auth.dispatchUser) ? props.auth.dispatchUser : null;

        this.initColumns = Array.from((billingType == BillingTypes.Purchase) ? initColumnsPurchase : (billingType == BillingTypes.Expense) ? initColumnsExpense : initColumnsInvoice);
        this.columns = Array.from(this.initColumns);

        this.initColumnVisibility = Array.from((billingType == BillingTypes.Purchase) ? initColumnVisibilityPurchase : (billingType == BillingTypes.Expense) ? initColumnVisibilityExpense : initColumnVisibilityInvoice);
        this.columnVisibility = Array.from(this.initColumnVisibility);

        this.state = {
          billingType: billingType,

          isBillable: null,
          isInvoiced: null,
          isPaid: null,
          isPurchase: null,
          isExpensed: null,
          statusLodaingText: 'Loading ...',

          isCompleted: true,
          isCancelled: false,
          isFailed: false,

          isLoadingRowDrawer: false,
          isVisibleRowDrawer: false,
          isBlockingRowDrawerForm: false,
          rowInitialValues: initialValues,
          rowValues: null,
          isBillableStatus: false,

          isLoadingTable: true,
          statsFilter: null
        };
    }


    async componentDidMount() {
      this.initDrawers();

      this.props.action.checkboxCount.clear();

      await this.props.action.dateSection.dateGetFilters({
        auth: this.props.auth,
        settingsType: UserCookieSettingType.BillingByJobDates,
        pageName: 'billing_invoice_purchase_date',
        // settingsType: this.props.settingsType,
        // pageName: this.pageName,
      });

      await this.props.action.tableSection.tableGetFilters({
        auth: this.props.auth,
        settingsType: this.props.settingsType,
        pageName: this.pageName,

        sortColumn: 'customerName',
        sortDir: 'asc',
        sortColumnInit: 'customerName',
        sortDirInit: 'asc',
        columnVisibility: this.columnVisibility,
        initColumnVisibility: this.initColumnVisibility,
        columns: this.columns,
        initColumns: this.initColumns,
      });
      await this.props.action.tableSection.tableChangeRows([]);

      await this.props.action.billingDefault.getDefault({
        auth: this.props.auth,
      });
      
      await this.props.action.jobStatuses.callReadJobStatusesApi();
      await this.changeStatuses();

      await this.callReadApi();
      
      let dedicatedBillingJobForm = (this.props.location && this.props.location.state && this.props.location.state.dedicatedBillingJobForm) ? this.props.location.state.dedicatedBillingJobForm : null;
      if(dedicatedBillingJobForm){
        let jobId = (dedicatedBillingJobForm && dedicatedBillingJobForm.jobId) ? dedicatedBillingJobForm.jobId : null;
        let job = (dedicatedBillingJobForm && dedicatedBillingJobForm.job) ? dedicatedBillingJobForm.job : null;

        Utils.colorRowsReset();
        
        try {
          this.changeJobs([job]);
        }catch(e){}
        
        try {
          Utils.colorRowsPrimery([jobId]);
        }catch(e){}

        try {
          Utils.scrollTo('row_' + jobId, -300);
        }catch(e){}
      }
    }
    async componentWillUnmount() {
      await this.props.action.cancelToken.cancel();
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.billingType !== this.state.billingType) {
        window.location.reload();
      }
    }


    /* API */
    callReadApi = async () => {
      this.setState({
        isLoadingTable: true,
      });

        let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
        let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());
    
        let jobStatusFilter = (this.props.jobStatuses.selectedIds && this.props.jobStatuses.selectedIds.length > 0) ? this.props.jobStatuses.selectedIds.join(',') : null;
        
        let data = {
            currentPage: this.props.tableSection.currentPage,
            pageSize: this.props.tableSection.rowsPerPageSelected,
            searchQuery: this.props.tableSection.search,
            fromDate: fromDate,
            toDate: toDate,
            sortColumn: this.props.tableSection.sortColumn,
            sortDir: this.props.tableSection.sortDir,
            jobStatusFilter: jobStatusFilter,
            billingType: this.state.billingType,
        };
        
        if(this.state.billingType == BillingTypes.Purchase){
          data['isBillable'] = this.state.isBillable;
          data['isPurchaseOrdered'] = this.state.isPurchase;
        } else if(this.state.billingType == BillingTypes.Expense){
          data['isBillable'] = this.state.isBillable;
          data['isExpensed'] = this.state.isExpensed;
        } else {
          data['isBillable'] = this.state.isBillable;
          data['isInvoiced'] = this.state.isInvoiced;
          data['isPaid'] = this.state.isPaid;
        }
        
        await this.props.action.tableSection.callTableReadApi({ params: data, path: 'job/billing/customer' });
        
        this.setState({
          isLoadingTable: false,
        });

        if(this.state.billingType == BillingTypes.Purchase){
          this.props.action.billingStatus.callPurchaseStatApi({
            searchQuery: this.props.tableSection.search,
            fromDate: this.props.dateSection.fromDate,
            toDate: this.props.dateSection.toDate,
            jobStatusFilter: jobStatusFilter,
            billingType: this.state.billingType,
          });
          
        } else if(this.state.billingType == BillingTypes.Expense){
          this.props.action.billingStatus.callExpenseStatApi({
            searchQuery: this.props.tableSection.search,
            fromDate: this.props.dateSection.fromDate,
            toDate: this.props.dateSection.toDate,
            jobStatusFilter: jobStatusFilter,
            billingType: this.state.billingType,
          });

        } else {
          this.props.action.billingStatus.callJobBillingStatApi({
            searchQuery: this.props.tableSection.search,
            fromDate: this.props.dateSection.fromDate,
            toDate: this.props.dateSection.toDate,
            jobStatusFilter: jobStatusFilter,
            billingType: this.state.billingType,
          });
          
          this.props.action.billingStatus.callJobInvoiceStatApi({
            searchQuery: this.props.tableSection.search,
            fromDate: this.props.dateSection.fromDate,
            toDate: this.props.dateSection.toDate,
            jobStatusFilter: jobStatusFilter,
            billingType: this.state.billingType,
          });
        }
    }
    callReadWithoutStatusesApi = async () => {
        this.setState({
          isLoadingTable: true,
        });

        let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
        let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());
    
        let jobStatusFilter = (this.props.jobStatuses.selectedIds && this.props.jobStatuses.selectedIds.length > 0) ? this.props.jobStatuses.selectedIds.join(',') : null;

        let data = {
            currentPage: this.props.tableSection.currentPage,
            pageSize: this.props.tableSection.rowsPerPageSelected,
            searchQuery: this.props.tableSection.search,
            fromDate: fromDate,
            toDate: toDate,
            sortColumn: this.props.tableSection.sortColumn,
            sortDir: this.props.tableSection.sortDir,
            jobStatusFilter: jobStatusFilter,
            billingType: this.state.billingType,
        };

        if(this.state.billingType == BillingTypes.Purchase){
          data['isBillable'] = this.state.isBillable;
          data['isPurchaseOrdered'] = this.state.isPurchase;
        } else if(this.state.billingType == BillingTypes.Expense){
          data['isBillable'] = this.state.isBillable;
          data['isExpensed'] = this.state.isExpensed;
        } else {
          data['isBillable'] = this.state.isBillable;
          data['isInvoiced'] = this.state.isInvoiced;
          data['isPaid'] = this.state.isPaid;
        }
        
        await this.props.action.tableSection.callTableReadApi({ params: data, path: 'job/billing/customer' });

        this.setState({
          isLoadingTable: false,
        });
    }
    callReadWithoutLoadingApi = async () => {
      let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
      let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());
  
      let jobStatusFilter = (this.props.jobStatuses.selectedIds && this.props.jobStatuses.selectedIds.length > 0) ? this.props.jobStatuses.selectedIds.join(',') : null;

      let data = {
          currentPage: this.props.tableSection.currentPage,
          pageSize: this.props.tableSection.rowsPerPageSelected,
          searchQuery: this.props.tableSection.search,
          fromDate: fromDate,
          toDate: toDate,
          sortColumn: this.props.tableSection.sortColumn,
          sortDir: this.props.tableSection.sortDir,
          jobStatusFilter: jobStatusFilter,
          billingType: this.state.billingType,
      };
      
      if(this.state.billingType == BillingTypes.Purchase){
        data['isBillable'] = this.state.isBillable;
        data['isPurchaseOrdered'] = this.state.isPurchase;
      } else if(this.state.billingType == BillingTypes.Expense){
        data['isBillable'] = this.state.isBillable;
        data['isExpensed'] = this.state.isExpensed;
      } else {
        data['isBillable'] = this.state.isBillable;
        data['isInvoiced'] = this.state.isInvoiced;
        data['isPaid'] = this.state.isPaid;
      }
      
      // await this.props.action.tableSection.callTableReadApi({ params: data, path: 'job/billing/customer' });
      
      if(this.state.billingType == BillingTypes.Purchase){
        this.props.action.billingStatus.callPurchaseStatApi({
          searchQuery: this.props.tableSection.search,
          fromDate: this.props.dateSection.fromDate,
          toDate: this.props.dateSection.toDate,
          jobStatusFilter: jobStatusFilter,
          billingType: this.state.billingType,
        });

      } else if(this.state.billingType == BillingTypes.Expense){
        this.props.action.billingStatus.callExpenseStatApi({
          searchQuery: this.props.tableSection.search,
          fromDate: this.props.dateSection.fromDate,
          toDate: this.props.dateSection.toDate,
          jobStatusFilter: jobStatusFilter,
          billingType: this.state.billingType,
        });

      } else {
        this.props.action.billingStatus.callJobBillingStatApi({
          searchQuery: this.props.tableSection.search,
          fromDate: this.props.dateSection.fromDate,
          toDate: this.props.dateSection.toDate,
          jobStatusFilter: jobStatusFilter,
          billingType: this.state.billingType,
        });
        
        this.props.action.billingStatus.callJobInvoiceStatApi({
          searchQuery: this.props.tableSection.search,
          fromDate: this.props.dateSection.fromDate,
          toDate: this.props.dateSection.toDate,
          jobStatusFilter: jobStatusFilter,
          billingType: this.state.billingType,
        });
      }
  }

    callDownloadExcelApi = async () => {
      await this.props.action.signalRslice.connect({
        callback: (percent) => {
          this.props.action.signalRslice.onPercentage(percent);
        },
        callbackInit: (hubId) => {}
      });

      let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
      let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());
  
      let jobStatusFilter = (this.props.jobStatuses.selectedIds && this.props.jobStatuses.selectedIds.length > 0) ? this.props.jobStatuses.selectedIds.join(',') : null;
      
      let data = {
          currentPage: 1,
          pageSize: Utils.getMaxPageSize(),
          searchQuery: this.props.tableSection.search,
          fromDate: fromDate,
          toDate: toDate,
          sortColumn: this.props.tableSection.sortColumn,
          sortDir: this.props.tableSection.sortDir,
          jobStatusFilter: jobStatusFilter,
          billingType: this.state.billingType,
          hubId: this.props.signalRslice.hubId,
      };
      
      if(this.state.billingType == BillingTypes.Purchase){
        data['isBillable'] = this.state.isBillable;
        data['isPurchaseOrdered'] = this.state.isPurchase;
        data['showAll'] = true;
      } else if(this.state.billingType == BillingTypes.Expense){
        data['isBillable'] = this.state.isBillable;
        data['isExpensed'] = this.state.isExpensed;
        data['showAll'] = true;
      } else {
        data['isBillable'] = this.state.isBillable;
        data['isInvoiced'] = this.state.isInvoiced;
        data['isPaid'] = this.state.isPaid;
      }

      let isIncludeInactive = ((this.props.tableSection.isIncludeInactive === true) || (this.props.tableSection.isIncludeInactive === false)) ? this.props.tableSection.isIncludeInactive : null;
      if (isIncludeInactive) {
        data['isIncludeInactive'] = isIncludeInactive;
      }

      let isInactive = ((this.props.tableSection.isInactive === true) || (this.props.tableSection.isInactive === false)) ? this.props.tableSection.isInactive : null;
      if (isInactive) {
        data['isInactive'] = isInactive;
      }
      
      await this.props.action.tableSection.callTableDownloadExcelApi({ params: data, path: 'job/billing/download-excel', caption: this.pageCaption });
      this.props.action.signalRslice.disconnect();
    }

    callQuickbookInvoiceHistoryApi = async (item) => {
      let data = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: '',
        sortDir: 'desc',
        cortColumn: 'invoiceDate',
        jobId: item.jobId,
      };
      
      await this.props.action.billingQuickbookXero.callQuickbookApi(data);
    }
    callXeroInvoiceHistoryApi = async (item) => {
      let data = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: '',
        sortDir: 'desc',
        cortColumn: 'invoiceDate',
        jobId: item.jobId,
      };
      
      await this.props.action.billingQuickbookXero.callXeroApi(data);
    }
    callQuickbookPurchaseOrderHistoryApi = async (item) => {
      let data = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: '',
        sortDir: 'desc',
        cortColumn: 'purchaseOrderDate',
        jobId: item.jobId,
      };
      
      await this.props.action.billingQuickbookXero.callQBPurchaseApi(data);
    }
    callQuickbookExpenseHistoryApi = async (item) => {
      let data = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: '',
        sortDir: 'desc',
        cortColumn: 'expenseDate',
        jobId: item.jobId,
      };
      
      await this.props.action.billingQuickbookXero.callQBExpenseApi(data);
    }

    batchUpdateBillingStatus = async (ids, status) => {
      let data = {
        jobIds: ids,
        status: status
      };
  
      await this.props.action.billingBatchAction.batchUpdateBillingStatus(data);
    }
    batchRefreshBillingStatus = async (ids) => {
      let data = {
        jobIds: ids,
      };
  
      await this.props.action.billingBatchAction.batchRefreshBillingStatus(data);
    }

    callJobBillingDetailsApi = async (id = null) => {
      if(id){
        this.setState({
          isBlockingRowDrawerForm: true,
        });
    
        await this.props.action.billing.callJobBillingDetailsApi(id);
      }
    }
    callSaveJobBillingApi = async (data = null) => {
      this.setState({
        isLoadingRowDrawer: true,
      });
  
      await this.props.action.billing.callSaveJobBillingApi(data);
    }

    callCustomerSitesApi = async (customerId = null) => {
      if(customerId){
        let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
        let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());
    
        let jobStatusFilter = (this.props.jobStatuses.selectedIds && this.props.jobStatuses.selectedIds.length > 0) ? this.props.jobStatuses.selectedIds.join(',') : null;
        
        let data = {
          currentPage: 1,
          pageSize: Utils.getMaxPageSize(),
          searchQuery: '',
          fromDate: fromDate,
          toDate: toDate,
          jobStatusFilter: jobStatusFilter,
          sortColumn: 'siteName',
          sortDir: 'asc',
          customerId: customerId,
          billingType: this.state.billingType,
          includeSiteJobs: true,
        };
        
        if(this.state.billingType == BillingTypes.Purchase){
          data['isBillable'] = this.state.isBillable;
          data['isPurchaseOrdered'] = this.state.isPurchase;
        } else if(this.state.billingType == BillingTypes.Expense){
          data['isBillable'] = this.state.isBillable;
          data['isExpensed'] = this.state.isExpensed;
        } else {
          data['isBillable'] = this.state.isBillable;
          data['isInvoiced'] = this.state.isInvoiced;
          data['isPaid'] = this.state.isPaid;
        }
        
        await this.props.action.billing.callCustomerSitesApi(data);
      }
    }
    
    callBillingAddressUpdateApi = async (customerId = 0, billingAddress = '', items) => {
      let data = {
        customerId: customerId,
        billingAddress: billingAddress,
      };
  
      await this.props.action.billing.callBillingAddressUpdateApi(data);
    }

    callPushJobInvoiceDataApi = async (data) => {
      await this.props.action.billingBatchAction.callPushJobInvoiceApi(data);
    }
    callPushJobPurchaseDataApi = async (data) => {
      await this.props.action.billingBatchAction.callPushJobPurchaseApi(data);
    }
    /* END API */

    
    /* FUNCTIONS */
    onWindowResize = async () => {
      await this.props.action.tableSection.tableSetFilters({
        auth: this.props.auth,
        settingsType: this.props.settingsType,
        pageName: this.pageName,

        columns: this.state.columns,
      });
    }
    
    initDrawers = () => {
      Utils.initDrawers(() => {
        this.rowDrawer = Utils.getInstanceDrawer('row');
        Utils.onShowDrawer(this.rowDrawer, null, () => {
          this.setState({
            isVisibleRowDrawer: true,
          });
        });
        Utils.onHideDrawer(this.rowDrawer, this.refRowDrawerForm, () => {
          this.setState({
            isVisibleRowDrawer: false,
          });
        });
      });
    }

    reselctAllCB = () => {
      try {
        this.selectedTableRef.current.tableRef.current.forEach((r) => {
          r.ref.setSelected();
        });
      }catch(e){}
      
      try {
        this.tableRefs.forEach((r) => {
          r.ref.setSelected();
        });
      }catch(e){}
    }
    expandAllRows = (state = false) => {
      try {
        if(this.tableRef && this.tableRef.current){
          this.tableRef.current.expandAllRows(state);
        }
      }catch(e){}
    }

    changeJobs = (jobs = []) => {
      try {
        setTimeout(() => {
          this.tableRefs.forEach((r) => {
            r.ref.changeRows(jobs);
          });
        }, 0)
      }catch(e){}
    }
    changeCustomers = async (customers = []) => {
      try {
        // if(this.tableRef && this.tableRef.current){
        //   this.tableRef.current.changeRows(customers);
        // }

        if(customers && customers.length > 0){
          let rows = _.cloneDeep(this.props.tableSection.rows);
    
          customers.forEach(row => {
            let index = rows.findIndex(x => x.customerId === row.customerId);
            if(index > -1){
              rows[index]['customer']['billingAddress'] = row.billingAddress;
              rows[index]['customer']['accountBillingProfileId'] = row.accountBillingProfileId;
              rows[index]['customer']['quickBookDefaultTermId'] = row.quickBookDefaultTermId;
              rows[index]['customer']['paymentTerms'] = row.paymentTerms;
              rows[index]['customer']['xeroPaymentTerm'] = row.xeroPaymentTerm;
              rows[index]['customer']['xeroPaymentTermType'] = row.xeroPaymentTermType;
            }
          });
    
          await this.props.action.tableSection.tableChangeRows(rows);
          if(this.tableRef && this.tableRef.current){
            this.tableRef.current.refrsh();
          }
        }
      }catch(e){}
    }

    collapseRow = async (row, obj) => {
      let rows = _.cloneDeep(this.props.tableSection.rows);
      rows.forEach((itm) => {
        itm['unbilledActive'] = false;
        itm['billedActive'] = false;
        itm['nonBillableActive'] = false;
        itm['unbilledPurchaseActive'] = false;
        itm['billedPurchaseActive'] = false;
        itm['nonBillablePurchaseActive'] = false;
        itm['unbilledExpenseActive'] = false;
        itm['billedExpenseActive'] = false;
        itm['nonBillableExpenseActive'] = false;
        itm['paidActive'] = false;
      });
      await this.props.action.tableSection.tableChangeRows(rows);
      obj.refrsh()
      obj.expandRow(row.customerId);
    }
    expandRow = async (index, field, row, obj) => {
      let rows = _.cloneDeep(this.props.tableSection.rows);
      rows.forEach((itm) => {
        itm['unbilledActive'] = false;
        itm['billedActive'] = false;
        itm['nonBillableActive'] = false;
        itm['unbilledPurchaseActive'] = false;
        itm['billedPurchaseActive'] = false;
        itm['nonBillablePurchaseActive'] = false;
        itm['unbilledExpenseActive'] = false;
        itm['billedExpenseActive'] = false;
        itm['nonBillableExpenseActive'] = false;
        itm['paidActive'] = false;
      });
      rows[index][field] = true;
      await this.props.action.tableSection.tableChangeRows(rows);
      obj.refrsh()
    }
    checkCollapseExpand = (row, field, value) => {
      let data = [ 'unbilledActive', 'billedActive', 'nonBillableActive', 'unbilledPurchaseActive', 'billedPurchaseActive', 'nonBillablePurchaseActive', 'unbilledExpenseActive', 'billedExpenseActive', 'nonBillableExpenseActive', 'paidActive' ];
      delete data[field];

      let state = false;
      for(let i = 0; i < data.length; i++){
        if(row[data[i]]){
          state = true;
          break;
        }
      }

      if(row[field]){
        return false;
      } else {
        if(!state){
          if(!row[field]){
            return true;
          } else {
            return state;
          }
        } else {
          return state;
        }
      }
    }
    
    createInvoicePurchase = async (jobId = null) => {
      Utils.colorRowsReset('.table-style-v5 > tbody > tr');

      this.props.action.invoiceNumber.show({ arr: [], ids: '', isCombined: false, billingType: this.state.billingType, billingProfile: null });

      let ids = this.props.checkboxCount.selected;
      let selectedArr = jobId ? [jobId] : ids;

      let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
      let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());
  
      let jobStatusFilter = (this.props.jobStatuses.selectedIds && this.props.jobStatuses.selectedIds.length > 0) ? this.props.jobStatuses.selectedIds.join(',') : null;

      let data = {
        jobIds: selectedArr,
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        // currentPage: this.props.tableSection.currentPage,
        // pageSize: this.props.tableSection.rowsPerPageSelected,
        // searchQuery: this.props.tableSection.search,
        // fromDate: fromDate,
        // toDate: toDate,
        sortColumn: 'customerName',
        sortDir: 'desc',
        // jobStatusFilter: jobStatusFilter,
        billingType: this.state.billingType,
      };

      if(this.state.billingType == BillingTypes.Purchase){
        data['includePurchaseOrderNumber'] = true;
      } else if(this.state.billingType == BillingTypes.Expense){
        data['includeExpenseNumber'] = true;
      } else {
        data['includeInvoiceNumber'] = true;
      }

      await this.props.action.invoiceNumberForm.callReadBillingProfileApi();
      
      this.props.action.invoiceNumber.callJobsApi({
        data: data,
      });
    }
    /* END FUNCTIONS */


    /* TOP SECTION */
    topSection = () => {
        return <ViewTopSection
            hasChecked={(this.props.checkboxCount.isSelected)}
            unchecked={this.topSectionUnchecked()}
            checked={this.topSectionChecked()}
        />
    }
    topSectionUnchecked = () => {
      return <Row className={"view-top-section-layout align-items-center"}>
        <Col xs={12} md={'auto'}>{this.setActiveDropdown()}</Col>
        <Col xs={'auto'} className={'pb-3 pb-lg-0'}>{this.setButtonArrow()}</Col>
        <Col xs={12} lg={4} xl={3} className={'pb-3 pb-lg-0'}>{this.setDatePicker()}</Col>
        <Col xs={'auto'} className={'pb-3 pb-lg-0'}>{this.setTodayButton()}</Col>
        <Col xs={'auto'} className={'pb-3 pb-lg-0'}>{this.setSearch()}</Col>
        <Col xs={true}></Col>
        <Col xs={'auto'} className={'text-center'}>{this.setSettingsDropdown()}</Col>
        <Col xs={'auto'} className={'text-center'}>{this.setColomnDropdown()}</Col>
      </Row>
    }
    topSectionChecked = () => {
      return <Row className={"align-items-center"}>
        <Col xs={12} md={'auto'}>{this.batchAction()}</Col>
        <Col xs={12} md={true}>{this.setSelectedItemsCount()}</Col>
        <Col xs={true}></Col>
        <Col xs={'auto'} className={'text-center'}>{this.setCloseButton()}</Col>
      </Row>
    }

    // UNCHECKED
    setActiveDropdown = () => {
      return <StyledDropdownIconButtonMenu 
        maxHeight={'auto'}
        overflowVisible={true}
        icon={<>
          <StyledDropDownTitle>{(this.state.billingType == BillingTypes.Purchase) ? 'Purchase' : (this.state.billingType == BillingTypes.Expense) ? 'Expense' : 'Invoice'}</StyledDropDownTitle>
          <ArrowDropDownIcon />
        </>}
      >
        <Row className={'px-4 py-1'} style={{ maxWidth: '300px' }}>
          <Col xs={12}>
            <Row className={'mb-3'}>
              <Col xs={true}>
                <StyledDropdownA href={'/billing/' + ((this.state.billingType == BillingTypes.Purchase) ? 'purchase' : (this.state.billingType == BillingTypes.Expense) ? 'expense' : 'invoice') + '-table/list-by-dates'}>List By Job Dates</StyledDropdownA>
              </Col>
              <Col xs={'auto'}>
                <StyledRoundedFormCheck
                  type={'checkbox'}
                  id={'defaultList1'}
                  disabled={false}
                  label={'Default'}
                  checked={this.props.billingDefault.isDefaultGroupByDate}
                  onChange={async (e) => {
                    await this.props.action.billingDefault.setDefault({
                      auth: this.props.auth,
                      isDefaultGroupByDate: true,
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Row>
              <Col xs={true}>
                <StyledDropdownA href={'/billing/' + ((this.state.billingType == BillingTypes.Purchase) ? 'purchase' : (this.state.billingType == BillingTypes.Expense) ? 'expense' : 'invoice') + '-table/group-by-customer'} active>Group By Customers</StyledDropdownA>
              </Col>
              <Col xs={'auto'}>
                <StyledRoundedFormCheck
                  type={'checkbox'}
                  id={'defaultList2'}
                  disabled={false}
                  label={'Default'}
                  checked={!this.props.billingDefault.isDefaultGroupByDate}
                  onChange={async (e) => {
                    await this.props.action.billingDefault.setDefault({
                      auth: this.props.auth,
                      isDefaultGroupByDate: false,
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledDropdownIconButtonMenu>
    }
    setButtonArrow = () => {
      return (
        <div>
          <StyledArrowButton
            className={'me-2'}
            variant="link"
            size={'sm'}
            onClick={async () => {
              await this.props.action.dateSection.datePrev();
              await this.props.action.dateSection.dateSetFilters({
                auth: this.props.auth,
                settingsType: UserCookieSettingType.BillingByJobDates,
                pageName: 'billing_invoice_purchase_date',
                // settingsType: this.props.settingsType,
                // pageName: this.pageName,
              });
              
              await this.props.action.tableSection.tableChangeCurrentPage(1);
              await this.props.action.tableSection.tableSetFilters({
                auth: this.props.auth,
                settingsType: this.props.settingsType,
                pageName: this.pageName,
              });

              this.expandAllRows();
              this.callReadApi();
            }}
          >
            <i color="blue" className="fas fa-arrow-left"></i>
          </StyledArrowButton>
          <StyledArrowButton
            variant="link"
            size={'sm'}
            onClick={async () => {
              await this.props.action.dateSection.dateNext();
              await this.props.action.dateSection.dateSetFilters({
                auth: this.props.auth,
                settingsType: UserCookieSettingType.BillingByJobDates,
                pageName: 'billing_invoice_purchase_date',
                // settingsType: this.props.settingsType,
                // pageName: this.pageName,
              });
              
              await this.props.action.tableSection.tableChangeCurrentPage(1);
              await this.props.action.tableSection.tableSetFilters({
                auth: this.props.auth,
                settingsType: this.props.settingsType,
                pageName: this.pageName,
              });

              this.expandAllRows();
              this.callReadApi();
            }}
          >
            <i className="fas fa-arrow-right"></i>
          </StyledArrowButton>
        </div>
      );
    }
    setDatePicker = () => {
      return <StyledDatePicker className={'date-range-picker'}>
        <RSuiteDateRangePickerCostume
          className={''}
          placeholder={"Select date"}
          ranges={Utils.getDateRanges(['Last 6 Months'])}
          value={this.props.dateSection.dateRange}
          cleanable={false}
          renderValue={(value, format) => {
            if( moment(value[0]).format(Utils.getDefaultDateRangeFormat())  === moment(value[1]).format(Utils.getDefaultDateRangeFormat())  ){
              let date = moment(value[0]).format(Utils.getDefaultDateRangeFormat()) 
            
              return (
                <div pl={1}>
                  <span className="dateTicker">{date}</span>
                </div>
              );
            } else {
              let date = moment(value[0]).format(Utils.getDefaultDateRangeFormat()) + " to " + moment(value[1]).format(Utils.getDefaultDateRangeFormat());
  
              return (
                <div pl={1}>
                  <span className="dateTicker">{date}</span>
                </div>
              );
            }
          }}
          onChange={async (value) => {
            await this.props.action.dateSection.dateChange(value);
            await this.props.action.dateSection.dateSetFilters({
              auth: this.props.auth,
              settingsType: UserCookieSettingType.BillingByJobDates,
              pageName: 'billing_invoice_purchase_date',
              // settingsType: this.props.settingsType,
              // pageName: this.pageName,
            });
              
            await this.props.action.tableSection.tableChangeCurrentPage(1);
            await this.props.action.tableSection.tableSetFilters({
              auth: this.props.auth,
              settingsType: this.props.settingsType,
              pageName: this.pageName,
            });
            
            this.expandAllRows();
            this.callReadApi();
          }}
        />
      </StyledDatePicker>
    }
    setTodayButton = () => {
      return <StyledButtonToday
        variant={"link"}
        size={'sm'}
        onClick={async () => {
          await this.props.action.dateSection.dateToday();
          await this.props.action.dateSection.dateSetFilters({
            auth: this.props.auth,
            settingsType: UserCookieSettingType.BillingByJobDates,
            pageName: 'billing_invoice_purchase_date',
            // settingsType: this.props.settingsType,
            // pageName: this.pageName,
          });
              
          await this.props.action.tableSection.tableChangeCurrentPage(1);
          await this.props.action.tableSection.tableSetFilters({
            auth: this.props.auth,
            settingsType: this.props.settingsType,
            pageName: this.pageName,
          });
          
          this.expandAllRows();
          this.callReadApi();
        }}
      >
        <span>TODAY</span>
      </StyledButtonToday>
    }
    setSearch = () => {
      return <IconButtonSearch 
        value={this.props.tableSection.search}
        isOpen={(this.props.tableSection.search !== '') ? true : false}
        onPressEnter={async (value) => {
          await this.props.action.tableSection.tableSearch(value);
          await this.props.action.tableSection.tableSetFilters({
            auth: this.props.auth,
            settingsType: this.props.settingsType,
            pageName: this.pageName,
          });
          this.expandAllRows();
          this.callReadApi();
        }}
        onClear={async () => {
          await this.props.action.tableSection.tableSearch('');
          await this.props.action.tableSection.tableSetFilters({
            auth: this.props.auth,
            settingsType: this.props.settingsType,
            pageName: this.pageName,
          });
          this.expandAllRows();
          this.callReadApi();
        }}
      />
    }
    setSettingsDropdown = () => {
      return <Dropdown>
        <Dropdown.Toggle as={DropdownToggle} isButton className={'px-3'} variant={"light"} size={"sm"}>
          <SettingsOutlinedIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            active={false}
            onClick={() => {
              this.callDownloadExcelApi();
            }} 
          >Download all in Excel format</Dropdown.Item>
          <Dropdown.Divider />
          <StyledDropdownHeader>Show Total Jobs</StyledDropdownHeader>
          <StyledFormCheckItem
            id={'Completed'}
            label={'Completed'}
            checked={this.state.isCompleted}
            onChange={(e) => {
              this.setState({
                isCompleted: e.target.checked,
              }, async () => {
                await this.changeStatuses();
                this.callReadApi();
              });
            }}
          />
          <StyledFormCheckItem
            id={'Cancelled'}
            label={'Cancelled'}
            checked={this.state.isCancelled}
            onChange={(e) => {
              this.setState({
                isCancelled: e.target.checked,
              }, async () => {
                await this.changeStatuses();
                this.callReadApi();
              });
            }}
          />
          <StyledFormCheckItem 
            id={'Failed'}
            label={'Failed'}
            checked={this.state.isFailed}
            onChange={(e) => {
              this.setState({
                isFailed: e.target.checked,
              }, async () => {
                await this.changeStatuses();
                this.callReadApi();
              });
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    }
    setColomnDropdown = () => {
      return <DropdownMenu
        className={'px-3'}
        variant={"light"}
        size={"sm"}
        text={<ViewHeadlineIcon />}
      >
        <DataTableActionMenu 
          pageName={this.pageName}
          columns={this.props.tableSection.columns}
          selected={this.props.tableSection.rowsPerPageSelected}
          selectItems={this.props.tableSection.rowsPerPageOptions}
          isCheckedIncludeInactive={this.props.tableSection.isIncludeInactive}
          columnVisibility={this.props.tableSection.columnVisibility}
          canSelectOption={false}
          onChangeColumnVisibility={async (index, value) => {
            let colVis = Array.from(this.props.tableSection.columnVisibility);
            colVis[index] = value;

            await this.props.action.tableSection.tableSetFilters({
              auth: this.props.auth,
              settingsType: this.props.settingsType,
              pageName: this.pageName,

              columns: this.props.tableSection.columns,
              columnVisibility: colVis,
              sortColumn: this.props.tableSection.sortColumn,
              sortDir: this.props.tableSection.sortDir,
            });
          }}
          canInactive={false}
          onSelectChangeAjax={async (val) => {
            await this.props.action.tableSection.tableChangeRowsPerPageSelected(val);
            await this.props.action.tableSection.tableSetFilters({
              auth: this.props.auth,
              settingsType: this.props.settingsType,
              pageName: this.pageName,
            });
            this.callReadApi();
          }}
          onChangeIncludeInactive={async (val) => {
            await this.props.action.tableSection.tableChangeIncludeInactive(val);
            await this.props.action.tableSection.tableSetFilters({
              auth: this.props.auth,
              settingsType: this.props.settingsType,
              pageName: this.pageName,
            });
            this.callReadApi();
          }}
          onResetColumns={async () => {
            await this.props.action.tableSection.tableSetFilters({
              auth: this.props.auth,
              settingsType: this.props.settingsType,
              pageName: this.pageName,

              columns: Array.from(this.props.tableSection.initColumns),
              columnVisibility: Array.from(this.props.tableSection.initColumnVisibility),
              sortColumn: this.props.tableSection.sortColumnInit,
              sortDir: this.props.tableSection.sortDirInit,
            });
            this.callReadApi();
            
            if(this.refDropdownActionMenu && this.refDropdownActionMenu.current){
              this.refDropdownActionMenu.current.hide();
            }
          }}
          onShowMore={(isShowedMore) => {
            this.setState({
              pageName: this.pageName,
            });
          }}
        />
      </DropdownMenu>
    }
    changeStatuses = async () => {
      let arr = [];

      if(this.state.isCompleted){
        arr.push('completed');
      }

      if(this.state.isCancelled){
        arr.push('cancelled');
      }

      if(this.state.isFailed){
        arr.push('failed');
      }

      await this.props.action.jobStatuses.getIdsByNames(arr);
              
      await this.props.action.tableSection.tableChangeCurrentPage(1);
      await this.props.action.tableSection.tableSetFilters({
        auth: this.props.auth,
        settingsType: this.props.settingsType,
        pageName: this.pageName,
      });
    }

    // CHECKED
    batchAction = () => {
      return <Dropdown alignRight as={ButtonGroup}>
        <Button
          onClick={() => {
            this.createInvoicePurchase(null);
          }}
        >Create {(this.state.billingType == BillingTypes.Purchase) ? 'Purchase' : (this.state.billingType == BillingTypes.Expense) ? 'Expense' : 'Invoice'}</Button>
        
        {(this.state.billingType == BillingTypes.Invoice) && <>
          <Dropdown.Toggle split />
          
          <Dropdown.Menu>
            <Dropdown.Item
                disabled={!this.props.checkboxCount.isSelected}
                active={false}
                onClick={async () => {
                  Utils.colorRowsReset('.table-style-v5 > tbody > tr');

                  let ids = '';
                  let selected = this.props.checkboxCount.selected;
                  if(selected && selected.length > 0){
                    ids = selected.join(',');
                    ids = ids.replace(/,+(?=,|$)/g, '');
                  }

                  await this.batchUpdateBillingStatus(ids, BillingStatus.NonBillable);
                  
                  try {
                    if(this.tableRef && this.tableRef.current){
                      this.tableRef.current.setSelected();
                    }
                    this.reselctAllCB();
                    this.props.action.checkboxCount.clear();
                  }catch(e){}
                  
                  try {
                    this.changeJobs(this.props.billingBatchAction.updateBillingStatus.successJobs);
                  }catch(e){}

                  try {
                    Utils.colorRowsPrimery(this.props.billingBatchAction.updateBillingStatus.successJobIds);
                    Utils.colorRowsDanger(this.props.billingBatchAction.updateBillingStatus.failedJobIds);
                  }catch(e){}

                  this.callReadWithoutLoadingApi();
                }}
            >Mark As Non-Billable</Dropdown.Item>
            <Dropdown.Item
                disabled={!this.props.checkboxCount.isSelected}
                active={false}
                onClick={async () => {
                  Utils.colorRowsReset('.table-style-v5 > tbody > tr');

                  let ids = '';
                  let selected = this.props.checkboxCount.selected;
                  if(selected && selected.length > 0){
                    ids = selected.join(',');
                    ids = ids.replace(/,+(?=,|$)/g, '');
                  }

                  await this.batchUpdateBillingStatus(ids, BillingStatus.Unbilled);
                  
                  try {
                    if(this.tableRef && this.tableRef.current){
                      this.tableRef.current.setSelected();
                    }
                    this.reselctAllCB();
                    this.props.action.checkboxCount.clear();
                  }catch(e){}
                  
                  try {
                    this.changeJobs(this.props.billingBatchAction.updateBillingStatus.successJobs);
                  }catch(e){}

                  try {
                    Utils.colorRowsPrimery(this.props.billingBatchAction.updateBillingStatus.successJobIds);
                    Utils.colorRowsDanger(this.props.billingBatchAction.updateBillingStatus.failedJobIds);
                  }catch(e){}
                  
                  this.callReadWithoutLoadingApi();
                }}
            >Mark As Unbilled</Dropdown.Item>
            <Dropdown.Item
                disabled={!this.props.checkboxCount.isSelected}
                active={false}
                onClick={async () => {
                  Utils.colorRowsReset('.table-style-v5 > tbody > tr');
                  
                  let ids = '';
                  let selected = this.props.checkboxCount.selected;
                  if(selected && selected.length > 0){
                    ids = selected.join(',');
                    ids = ids.replace(/,+(?=,|$)/g, '');
                  }

                  await this.batchUpdateBillingStatus(ids, BillingStatus.MarkAsBilled);
                  
                  try {
                    if(this.tableRef && this.tableRef.current){
                      this.tableRef.current.setSelected();
                    }
                    this.reselctAllCB();
                    this.props.action.checkboxCount.clear();
                  }catch(e){}
                  
                  try {
                    this.changeJobs(this.props.billingBatchAction.updateBillingStatus.successJobs);
                  }catch(e){}

                  try {
                    Utils.colorRowsPrimery(this.props.billingBatchAction.updateBillingStatus.successJobIds);
                    Utils.colorRowsDanger(this.props.billingBatchAction.updateBillingStatus.failedJobIds);
                  }catch(e){}
                  
                  this.callReadWithoutLoadingApi();
                }}
            >Mark As Billed</Dropdown.Item>
            <Dropdown.Item
                disabled={!this.props.checkboxCount.isSelected}
                active={false}
                onClick={async () => {
                  Utils.colorRowsReset('.table-style-v5 > tbody > tr');
                  
                  let ids = '';
                  let selected = this.props.checkboxCount.selected;
                  if(selected && selected.length > 0){
                    ids = selected.join(',');
                    ids = ids.replace(/,+(?=,|$)/g, '');
                  }

                  await this.batchUpdateBillingStatus(ids, BillingStatus.MarkAsPaid);
                  
                  try {
                    if(this.tableRef && this.tableRef.current){
                      this.tableRef.current.setSelected();
                    }
                    this.reselctAllCB();
                    this.props.action.checkboxCount.clear();
                  }catch(e){}
                  
                  try {
                    this.changeJobs(this.props.billingBatchAction.updateBillingStatus.successJobs);
                  }catch(e){}

                  try {
                    Utils.colorRowsPrimery(this.props.billingBatchAction.updateBillingStatus.successJobIds);
                    Utils.colorRowsDanger(this.props.billingBatchAction.updateBillingStatus.failedJobIds);
                  }catch(e){}
                  
                  this.callReadWithoutLoadingApi();
                }}
            >Mark As Paid</Dropdown.Item>
            <Dropdown.Divider />

            <Dropdown.Item
                disabled={!this.props.checkboxCount.isSelected}
                active={false}
                onClick={async () => {
                  Utils.colorRowsReset('.table-style-v5 > tbody > tr');
                  
                  let ids = '';
                  let selected = this.props.checkboxCount.selected;
                  if(selected && selected.length > 0){
                    ids = selected.join(',');
                    ids = ids.replace(/,+(?=,|$)/g, '');
                  }

                  await this.batchRefreshBillingStatus(ids);
                  
                  try {
                    if(this.tableRef && this.tableRef.current){
                      this.tableRef.current.setSelected();
                    }
                    this.reselctAllCB();
                    this.props.action.checkboxCount.clear();
                  }catch(e){}
                  
                  try {
                    this.changeJobs(this.props.billingBatchAction.refreshBillingStatus.successJobs);
                  }catch(e){}

                  try {
                    Utils.colorRowsPrimery(this.props.billingBatchAction.refreshBillingStatus.successJobIds);
                    Utils.colorRowsDanger(this.props.billingBatchAction.refreshBillingStatus.failedJobIds);
                  }catch(e){}
                  
                  this.callReadWithoutLoadingApi();
                }}
            >Refresh Billing Status</Dropdown.Item>
          </Dropdown.Menu>
        </>}
      </Dropdown>
    }
    setSelectedItemsCount = () => {
      return <span>{this.props.checkboxCount.count} item(s) selected</span>
    }
    setCloseButton = () => {
      return <WrappedButton
        className={'px-3 pe-1'}
        variant={"light"}
        size={'sm'}
        style={{ backgroundColor: 'transparent' }}
        onClick={() => {
          Utils.colorRowsReset('.table-style-v5 > tbody > tr');
          
          try {
            if(this.tableRef && this.tableRef.current){
              this.tableRef.current.setSelected([], async () => {
                await this.props.action.tableSection.tableChangeDisabledBatchAction(true);
              });
            }
            this.reselctAllCB();
            this.props.action.checkboxCount.clear();
          }catch(e){}
        }}
      >
        <i className={'material-icons'}>close</i>
      </WrappedButton>
    }
    /* END TOP SECTION */


    /* STATUS */
    statusSectionInvoice = () => {
      let { billingStatus, invoiceStatus } = this.props.billingInvoiceStatusSection;

      return <Row>

        <Col xs={12} className={'d-none d-sm-block'}>

          {
            (!billingStatus.isLoading && !invoiceStatus.isLoading)
            ?
            <Row className={'gy-5'}>
              <Col xs={12} lg={6}>
                <StyledStatusTitle
                  onClick={() => {
                    if(!this.props.checkboxCount.isSelected){
                      this.setState({
                        statsFilter: BillingStatusParams.All
                      });
                      this.setState(BillingStatusParams.All, async () => {
                        await this.props.action.tableSection.tableChangeCurrentPage(1);
                        await this.props.action.tableSection.tableSetFilters({
                          auth: this.props.auth,
                          settingsType: this.props.settingsType,
                          pageName: this.pageName,
                        });

                        this.expandAllRows();
                        this.callReadWithoutStatusesApi();
                      });
                    }
                  }}
                >
                  <CountUp
                    delay={0}
                    start={0}
                    end={billingStatus.status1total}
                    duration={2}
                    decimals={0}
                    separator=","
                    decimal="."
                    prefix=""
                    suffix={' TOTAL JOBS'}
                  >
                    {({ countUpRef }) => (
                      <div className={'progress-label-child-1'} ref={countUpRef} />
                    )}
                  </CountUp>
                </StyledStatusTitle>

                <StyledProgressBar>
                  {(billingStatus.status1Progress1percent > 0) && <StyledProgressBarChild
                    now={billingStatus.status1Progress1percent}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState({
                          statsFilter: BillingStatusParams.Billable
                        });
                        this.setState(BillingStatusParams.Billable, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.expandAllRows();
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'billable'}
                    label={<Tooltip
                      title={<div>
                        <div>{billingStatus.status1Progress1count + ' ' + billingStatus.status1Progress1name}</div>
                        <div>{Utils.getCurrency() + ' ' + Utils.formatNumber(billingStatus.status1Progress1total)}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                        <CountUp
                          delay={0}
                          start={0}
                          end={billingStatus.status1Progress1count}
                          duration={2}
                          decimals={0}
                          separator=","
                          decimal="."
                          prefix=""
                          suffix={' ' + billingStatus.status1Progress1name}
                        >
                          {({ countUpRef }) => (
                            <div className={'progress-label-child-1'} ref={countUpRef} />
                          )}
                        </CountUp>
                        <CountUp
                          delay={0}
                          start={0}
                          end={billingStatus.status1Progress1total}
                          duration={2}
                          decimals={2}
                          separator=","
                          decimal="."
                          prefix={Utils.getCurrency()}
                          suffix=""
                        >
                          {({ countUpRef }) => (
                            <div className={'progress-label-child-2'} ref={countUpRef} />
                          )}
                        </CountUp>
                      </div>
                    </Tooltip>}
                  />}

                  {(billingStatus.status1Progress2percent > 0) && <StyledProgressBarChild
                    now={billingStatus.status1Progress2percent}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState({
                          statsFilter: BillingStatusParams.NonBillable
                        });
                        this.setState(BillingStatusParams.NonBillable, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.expandAllRows();
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'nonBillable'}
                    label={<Tooltip
                      title={<div>
                        <div>{billingStatus.status1Progress2count + ' ' + billingStatus.status1Progress2name}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                        <CountUp
                          delay={0}
                          start={0}
                          end={billingStatus.status1Progress2count}
                          duration={2}
                          decimals={0}
                          separator=","
                          decimal="."
                          prefix=""
                          suffix={' ' + billingStatus.status1Progress2name}
                        >
                          {({ countUpRef }) => (
                            <div className={'progress-label-child-1'} ref={countUpRef} />
                          )}
                        </CountUp>
                      </div>
                    </Tooltip>}
                  />}
                </StyledProgressBar>
              </Col>
              <Col xs={12} lg={6}>
                <StyledStatusTitle
                  onClick={() => {
                    if(!this.props.checkboxCount.isSelected){
                      this.setState({
                        statsFilter: BillingStatusParams.Billable
                      });
                      this.setState(BillingStatusParams.Billable, async () => {
                        await this.props.action.tableSection.tableChangeCurrentPage(1);
                        await this.props.action.tableSection.tableSetFilters({
                          auth: this.props.auth,
                          settingsType: this.props.settingsType,
                          pageName: this.pageName,
                        });
                        
                        this.expandAllRows();
                        this.callReadWithoutStatusesApi();
                      });
                    }
                  }}
                >
                  <CountUp
                    delay={0}
                    start={0}
                    end={invoiceStatus.status2total}
                    duration={2}
                    decimals={0}
                    separator=","
                    decimal="."
                    prefix=""
                    suffix={' BILLABLE JOBS'}
                  >
                    {({ countUpRef }) => (
                      <div className={'progress-label-child-1'} ref={countUpRef} />
                    )}
                  </CountUp>
                </StyledStatusTitle>

                <StyledProgressBar>
                  {(invoiceStatus.status2Progress1percent > 0) && <StyledProgressBarChild
                    now={invoiceStatus.status2Progress1percent}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState({
                          statsFilter: BillingStatusParams.Unbilled
                        });
                        this.setState(BillingStatusParams.Unbilled, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.expandAllRows();
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'unbilled'}
                    label={<Tooltip
                      title={<div>
                        <div>{invoiceStatus.status2Progress1count + ' ' + invoiceStatus.status2Progress1name}</div>
                        <div>{Utils.getCurrency() + ' ' + Utils.formatNumber(invoiceStatus.status2Progress1total)}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                        <CountUp
                          delay={0}
                          start={0}
                          end={invoiceStatus.status2Progress1count}
                          duration={2}
                          decimals={0}
                          separator=","
                          decimal="."
                          prefix=""
                          suffix={' ' + invoiceStatus.status2Progress1name}
                        >
                          {({ countUpRef }) => (
                            <div className={'progress-label-child-1'} ref={countUpRef} />
                          )}
                        </CountUp>
                        <CountUp
                          delay={0}
                          start={0}
                          end={invoiceStatus.status2Progress1total}
                          duration={2}
                          decimals={2}
                          separator=","
                          decimal="."
                          prefix={Utils.getCurrency()}
                          suffix=""
                        >
                          {({ countUpRef }) => (
                            <div className={'progress-label-child-2'} ref={countUpRef} />
                          )}
                        </CountUp>
                      </div>
                    </Tooltip>}
                  />}
                  {(invoiceStatus.status2Progress2percent > 0) && <StyledProgressBarChild
                    now={invoiceStatus.status2Progress2percent}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState({
                          statsFilter: BillingStatusParams.Billed
                        });
                        this.setState(BillingStatusParams.Billed, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.expandAllRows();
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'billed'}
                    label={<Tooltip
                      title={<div>
                        <div>{invoiceStatus.status2Progress2count + ' ' + invoiceStatus.status2Progress2name}</div>
                        <div>{Utils.getCurrency() + ' ' + Utils.formatNumber(invoiceStatus.status2Progress2total)}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                        <CountUp
                          delay={0}
                          start={0}
                          end={invoiceStatus.status2Progress2count}
                          duration={2}
                          decimals={0}
                          separator=","
                          decimal="."
                          prefix=""
                          suffix={' ' + invoiceStatus.status2Progress2name}
                        >
                          {({ countUpRef }) => (
                            <div className={'progress-label-child-1'} ref={countUpRef} />
                          )}
                        </CountUp>
                        <CountUp
                          delay={0}
                          start={0}
                          end={invoiceStatus.status2Progress2total}
                          duration={2}
                          decimals={2}
                          separator=","
                          decimal="."
                          prefix={Utils.getCurrency()}
                          suffix=""
                        >
                          {({ countUpRef }) => (
                            <div className={'progress-label-child-2'} ref={countUpRef} />
                          )}
                        </CountUp>
                      </div>
                    </Tooltip>}
                  />}
                  {(invoiceStatus.status2Progress3percent > 0) && <StyledProgressBarChild
                    now={invoiceStatus.status2Progress3percent}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState({
                          statsFilter: BillingStatusParams.Paid
                        });
                        this.setState(BillingStatusParams.Paid, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.expandAllRows();
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'paid'}
                    label={<Tooltip
                      title={<div>
                        <div>{invoiceStatus.status2Progress3count + ' ' + invoiceStatus.status2Progress3name}</div>
                        <div>{Utils.getCurrency() + ' ' + Utils.formatNumber(invoiceStatus.status2Progress3total)}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                        <CountUp
                          delay={0}
                          start={0}
                          end={invoiceStatus.status2Progress3count}
                          duration={2}
                          decimals={0}
                          separator=","
                          decimal="."
                          prefix=""
                          suffix={' ' + invoiceStatus.status2Progress3name}
                        >
                          {({ countUpRef }) => (
                            <div className={'progress-label-child-1'} ref={countUpRef} />
                          )}
                        </CountUp>
                        <CountUp
                          delay={0}
                          start={0}
                          end={invoiceStatus.status2Progress3total}
                          duration={2}
                          decimals={2}
                          separator=","
                          decimal="."
                          prefix={Utils.getCurrency()}
                          suffix=""
                        >
                          {({ countUpRef }) => (
                            <div className={'progress-label-child-2'} ref={countUpRef} />
                          )}
                        </CountUp>
                      </div>
                    </Tooltip>}
                  />}
                </StyledProgressBar>
              </Col>
            </Row>
            :
            <Row className={'gy-5'}>
              <Col xs={12} lg={6}>
                <StyledStatusTitle>&nbsp;</StyledStatusTitle>

                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    striped
                    animated
                    variant={'nonBillable'}
                    label={<div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{this.state.statusLodaingText}</div>
                    </div>}
                  />
                </StyledProgressBar>
              </Col>
              <Col xs={12} lg={6}>
                <StyledStatusTitle>&nbsp;</StyledStatusTitle>

                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    striped
                    animated
                    variant={'nonBillable'}
                    label={<div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{this.state.statusLodaingText}</div>
                    </div>}
                  />
                </StyledProgressBar>
              </Col>
            </Row>
          }

        </Col>

        <Col xs={12} className={'d-block d-sm-none'}>
          <Row>

            {
              !billingStatus.isLoading
              ?
              <Col xs={12} className={'mb-2'}>
                <StyledStatusTitle
                  onClick={() => {
                    if(!this.props.checkboxCount.isSelected){
                      this.setState({
                        statsFilter: BillingStatusParams.All
                      });
                      this.setState(BillingStatusParams.All, async () => {
                        await this.props.action.tableSection.tableChangeCurrentPage(1);
                        await this.props.action.tableSection.tableSetFilters({
                          auth: this.props.auth,
                          settingsType: this.props.settingsType,
                          pageName: this.pageName,
                        });
                        
                        this.expandAllRows();
                        this.callReadWithoutStatusesApi();
                      });
                    }
                  }}
                >{billingStatus.status1total + '  + TOTAL JOBS'}</StyledStatusTitle>
              </Col>
              :
              <Col xs={12} className={'mb-2'}>
                <StyledStatusTitle>&nbsp;</StyledStatusTitle>
              </Col>
            }

            {
              !billingStatus.isLoading
              ?
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState({
                          statsFilter: BillingStatusParams.Billable
                        });
                        this.setState(BillingStatusParams.Billable, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.expandAllRows();
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'billable'}
                    label={<Tooltip
                      title={<div>
                        <div>{billingStatus.status1Progress1count + ' ' + billingStatus.status1Progress1name}</div>
                        <div>{Utils.getCurrency() + ' ' + Utils.formatNumber(billingStatus.status1Progress1total)}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{billingStatus.status1Progress1count + ' ' + billingStatus.status1Progress1name}</div>
                      <div className={'progress-label-child-2'}>{Utils.getCurrency() + ' ' + Utils.formatNumber(billingStatus.status1Progress1total)}</div>
                    </div>
                    </Tooltip>}
                  />
                </StyledProgressBar>
              </Col>
              :
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    striped
                    animated
                    variant={'nonBillable'}
                    label={<div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{this.state.statusLodaingText}</div>
                    </div>}
                  />
                </StyledProgressBar>
              </Col>
            }

            {
              !billingStatus.isLoading
              ?
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState({
                          statsFilter: BillingStatusParams.NonBillable
                        });
                        this.setState(BillingStatusParams.NonBillable, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.expandAllRows();
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'nonBillable'}
                    label={<Tooltip
                      title={<div>
                        <div>{billingStatus.status1Progress2count + ' ' + billingStatus.status1Progress2name}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{billingStatus.status1Progress2count + ' ' + billingStatus.status1Progress2name}</div>
                    </div>
                    </Tooltip>}
                  />
                </StyledProgressBar>
              </Col>
              :
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    striped
                    animated
                    variant={'nonBillable'}
                    label={<div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{this.state.statusLodaingText}</div>
                    </div>}
                  />
                </StyledProgressBar>
              </Col>
            }



            {
              !invoiceStatus.isLoading
              ?
              <Col xs={12} className={'mb-2'}>
                <StyledStatusTitle
                  onClick={() => {
                    if(!this.props.checkboxCount.isSelected){
                      this.setState({
                        statsFilter: BillingStatusParams.Billable
                      });
                      this.setState(BillingStatusParams.Billable, async () => {
                        await this.props.action.tableSection.tableChangeCurrentPage(1);
                        await this.props.action.tableSection.tableSetFilters({
                          auth: this.props.auth,
                          settingsType: this.props.settingsType,
                          pageName: this.pageName,
                        });
                        
                        this.expandAllRows();
                        this.callReadWithoutStatusesApi();
                      });
                    }
                  }}
                >{invoiceStatus.status2total} BILLABLE JOBS</StyledStatusTitle>
              </Col>
              :
              <Col xs={12} className={'mb-2'}>
                <StyledStatusTitle>&nbsp;</StyledStatusTitle>
              </Col>
            }

            {
            !invoiceStatus.isLoading
            ?
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState({
                          statsFilter: BillingStatusParams.Unbilled
                        });
                        this.setState(BillingStatusParams.Unbilled, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.expandAllRows();
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'unbilled'}
                    label={<Tooltip
                      title={<div>
                        <div>{invoiceStatus.status2Progress1count + ' ' + invoiceStatus.status2Progress1name}</div>
                        <div>{Utils.getCurrency() + ' ' + Utils.formatNumber(invoiceStatus.status2Progress1total)}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{invoiceStatus.status2Progress1count + ' ' + invoiceStatus.status2Progress1name}</div>
                      <div className={'progress-label-child-2'}>{Utils.getCurrency() + ' ' + Utils.formatNumber(invoiceStatus.status2Progress1total)}</div>
                    </div>
                    </Tooltip>}
                  />
                </StyledProgressBar>
              </Col>
              :
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    striped
                    animated
                    variant={'nonBillable'}
                    label={<div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{this.state.statusLodaingText}</div>
                    </div>}
                  />
                </StyledProgressBar>
              </Col>
            }

            {
              !invoiceStatus.isLoading
              ?
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState({
                          statsFilter: BillingStatusParams.Billed
                        });
                        this.setState(BillingStatusParams.Billed, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.expandAllRows();
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'billed'}
                    label={<Tooltip
                      title={<div>
                        <div>{invoiceStatus.status2Progress2count + ' ' + invoiceStatus.status2Progress2name}</div>
                        <div>{Utils.getCurrency() + ' ' + Utils.formatNumber(invoiceStatus.status2Progress2total)}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{invoiceStatus.status2Progress2count + ' ' + invoiceStatus.status2Progress2name}</div>
                      <div className={'progress-label-child-2'}>{Utils.getCurrency() + ' ' + Utils.formatNumber(invoiceStatus.status2Progress2total)}</div>
                    </div>
                    </Tooltip>}
                  />
                </StyledProgressBar>
              </Col>
              :
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    striped
                    animated
                    variant={'nonBillable'}
                    label={<div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{this.state.statusLodaingText}</div>
                    </div>}
                  />
                </StyledProgressBar>
              </Col>
            }

            {
              !invoiceStatus.isLoading
              ?
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState({
                          statsFilter: BillingStatusParams.Paid
                        });
                        this.setState(BillingStatusParams.Paid, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.expandAllRows();
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'paid'}
                    label={<Tooltip
                      title={<div>
                        <div>{invoiceStatus.status2Progress3count + ' ' + invoiceStatus.status2Progress3name}</div>
                        <div>{Utils.getCurrency() + ' ' + Utils.formatNumber(invoiceStatus.status2Progress3total)}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{invoiceStatus.status2Progress3count + ' ' + invoiceStatus.status2Progress3name}</div>
                      <div className={'progress-label-child-2'}>{Utils.getCurrency() + ' ' + Utils.formatNumber(invoiceStatus.status2Progress3total)}</div>
                    </div>
                    </Tooltip>}
                  />
                </StyledProgressBar>
              </Col>
              :
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    striped
                    animated
                    variant={'nonBillable'}
                    label={<div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{this.state.statusLodaingText}</div>
                    </div>}
                  />
                </StyledProgressBar>
              </Col>
            }

          </Row>
        </Col>

      </Row>
    }
    statusSectionPurchase = () => {
      let { purchaseStatus } = this.props.billingInvoiceStatusSection;

      return <Row>

        <Col xs={12} className={'d-none d-sm-block'}>

          {
            (!purchaseStatus.isLoading)
            ?
            <Row className={'gy-5'}>
              <Col xs={12}>
                <StyledStatusTitle
                  onClick={() => {
                    if(!this.props.checkboxCount.isSelected){
                      this.setState({
                        statsFilter: BillingStatusParams.All
                      });
                      this.setState(BillingStatusParams.All, async () => {
                        await this.props.action.tableSection.tableChangeCurrentPage(1);
                        await this.props.action.tableSection.tableSetFilters({
                          auth: this.props.auth,
                          settingsType: this.props.settingsType,
                          pageName: this.pageName,
                        });
                        
                        this.expandAllRows();
                        this.callReadWithoutStatusesApi();
                      });
                    }
                  }}
                >
                  <CountUp
                    delay={0}
                    start={0}
                    end={purchaseStatus.status1total}
                    duration={2}
                    decimals={0}
                    separator=","
                    decimal="."
                    prefix=""
                    suffix={' TOTAL JOBS'}
                  >
                    {({ countUpRef }) => (
                      <div className={'progress-label-child-1'} ref={countUpRef} />
                    )}
                  </CountUp>
                </StyledStatusTitle>

                <StyledProgressBar>
                  {(purchaseStatus.status1Progress1percent > 0) && <StyledProgressBarChild
                    now={purchaseStatus.status1Progress1percent}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState({
                          statsFilter: BillingStatusParams.isPurchase
                        });
                        this.setState(BillingStatusParams.isPurchase, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.expandAllRows();
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'paid'}
                    label={<Tooltip
                      title={<div>
                        <div>{purchaseStatus.status1Progress1count + ' ' + purchaseStatus.status1Progress1name}</div>
                        <div>{Utils.getCurrency() + ' ' + Utils.formatNumber(purchaseStatus.status1Progress1total)}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                        <CountUp
                          delay={0}
                          start={0}
                          end={purchaseStatus.status1Progress1count}
                          duration={2}
                          decimals={0}
                          separator=","
                          decimal="."
                          prefix=""
                          suffix={' ' + purchaseStatus.status1Progress1name}
                        >
                          {({ countUpRef }) => (
                            <div className={'progress-label-child-1'} ref={countUpRef} />
                          )}
                        </CountUp>
                        <CountUp
                          delay={0}
                          start={0}
                          end={purchaseStatus.status1Progress1total}
                          duration={2}
                          decimals={2}
                          separator=","
                          decimal="."
                          prefix={Utils.getCurrency()}
                          suffix=""
                        >
                          {({ countUpRef }) => (
                            <div className={'progress-label-child-2'} ref={countUpRef} />
                          )}
                        </CountUp>
                      </div>
                    </Tooltip>}
                  />}

                  {(purchaseStatus.status1Progress2percent > 0) && <StyledProgressBarChild
                    now={purchaseStatus.status1Progress2percent}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState({
                          statsFilter: BillingStatusParams.isNotPurchase
                        });
                        this.setState(BillingStatusParams.isNotPurchase, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.expandAllRows();
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'unbilled'}
                    label={<Tooltip
                      title={<div>
                        <div>{purchaseStatus.status1Progress2count + ' ' + purchaseStatus.status1Progress2name}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                        <CountUp
                          delay={0}
                          start={0}
                          end={purchaseStatus.status1Progress2count}
                          duration={2}
                          decimals={0}
                          separator=","
                          decimal="."
                          prefix=""
                          suffix={' ' + purchaseStatus.status1Progress2name}
                        >
                          {({ countUpRef }) => (
                            <div className={'progress-label-child-1'} ref={countUpRef} />
                          )}
                        </CountUp>
                      </div>
                    </Tooltip>}
                  />}
                </StyledProgressBar>
              </Col>
            </Row>
            :
            <Row className={'gy-5'}>
              <Col xs={12}>
                <StyledStatusTitle>&nbsp;</StyledStatusTitle>

                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    striped
                    animated
                    variant={'nonBillable'}
                    label={<div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{this.state.statusLodaingText}</div>
                    </div>}
                  />
                </StyledProgressBar>
              </Col>
            </Row>
          }

        </Col>

        <Col xs={12} className={'d-block d-sm-none'}>
          <Row>

            {
              !purchaseStatus.isLoading
              ?
              <Col xs={12} className={'mb-2'}>
                <StyledStatusTitle
                  onClick={() => {
                    if(!this.props.checkboxCount.isSelected){
                      this.setState({
                        statsFilter: BillingStatusParams.All
                      });
                      this.setState(BillingStatusParams.All, async () => {
                        await this.props.action.tableSection.tableChangeCurrentPage(1);
                        await this.props.action.tableSection.tableSetFilters({
                          auth: this.props.auth,
                          settingsType: this.props.settingsType,
                          pageName: this.pageName,
                        });
                        
                        this.expandAllRows();
                        this.callReadWithoutStatusesApi();
                      });
                    }
                  }}
                >{purchaseStatus.status1total + '  + TOTAL JOBS'}</StyledStatusTitle>
              </Col>
              :
              <Col xs={12} className={'mb-2'}>
                <StyledStatusTitle>&nbsp;</StyledStatusTitle>
              </Col>
            }

            {
              !purchaseStatus.isLoading
              ?
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState({
                          statsFilter: BillingStatusParams.isPurchase
                        });
                        this.setState(BillingStatusParams.isPurchase, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.expandAllRows();
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'paid'}
                    label={<Tooltip
                      title={<div>
                        <div>{purchaseStatus.status1Progress1count + ' ' + purchaseStatus.status1Progress1name}</div>
                        <div>{Utils.getCurrency() + ' ' + Utils.formatNumber(purchaseStatus.status1Progress1total)}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{purchaseStatus.status1Progress1count + ' ' + purchaseStatus.status1Progress1name}</div>
                      <div className={'progress-label-child-2'}>{Utils.getCurrency() + ' ' + Utils.formatNumber(purchaseStatus.status1Progress1total)}</div>
                    </div>
                    </Tooltip>}
                  />
                </StyledProgressBar>
              </Col>
              :
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    striped
                    animated
                    variant={'nonBillable'}
                    label={<div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{this.state.statusLodaingText}</div>
                    </div>}
                  />
                </StyledProgressBar>
              </Col>
            }

            {
              !purchaseStatus.isLoading
              ?
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState({
                          statsFilter: BillingStatusParams.isNotPurchase
                        });
                        this.setState(BillingStatusParams.isNotPurchase, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.expandAllRows();
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'unbilled'}
                    label={<Tooltip
                      title={<div>
                        <div>{purchaseStatus.status1Progress2count + ' ' + purchaseStatus.status1Progress2name}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{purchaseStatus.status1Progress2count + ' ' + purchaseStatus.status1Progress2name}</div>
                    </div>
                    </Tooltip>}
                  />
                </StyledProgressBar>
              </Col>
              :
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    striped
                    animated
                    variant={'nonBillable'}
                    label={<div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{this.state.statusLodaingText}</div>
                    </div>}
                  />
                </StyledProgressBar>
              </Col>
            }

          </Row>
        </Col>

      </Row>
    }
    statusSectionExpense = () => {
      let { expenseStatus } = this.props.billingInvoiceStatusSection;

      return <Row>

        <Col xs={12} className={'d-none d-sm-block'}>

          {
            (!expenseStatus.isLoading)
            ?
            <Row className={'gy-5'}>
              <Col xs={12}>
                <StyledStatusTitle
                  onClick={() => {
                    if(!this.props.checkboxCount.isSelected){
                      this.setState(BillingStatusParams.All, async () => {
                        await this.props.action.tableSection.tableChangeCurrentPage(1);
                        await this.props.action.tableSection.tableSetFilters({
                          auth: this.props.auth,
                          settingsType: this.props.settingsType,
                          pageName: this.pageName,
                        });

                        this.callReadWithoutStatusesApi();
                      });
                    }
                  }}
                >
                  <CountUp
                    delay={0}
                    start={0}
                    end={expenseStatus.status1total}
                    duration={2}
                    decimals={0}
                    separator=","
                    decimal="."
                    prefix=""
                    suffix={' TOTAL JOBS'}
                  >
                    {({ countUpRef }) => (
                      <div className={'progress-label-child-1'} ref={countUpRef} />
                    )}
                  </CountUp>
                </StyledStatusTitle>

                <StyledProgressBar>
                  {(expenseStatus.status1Progress1percent > 0) && <StyledProgressBarChild
                    now={expenseStatus.status1Progress1percent}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState(BillingStatusParams.isExpensed, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'paid'}
                    label={<Tooltip
                      title={<div>
                        <div>{expenseStatus.status1Progress1count + ' ' + expenseStatus.status1Progress1name}</div>
                        <div>{Utils.getCurrency() + ' ' + Utils.formatNumber(expenseStatus.status1Progress1total)}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                        <CountUp
                          delay={0}
                          start={0}
                          end={expenseStatus.status1Progress1count}
                          duration={2}
                          decimals={0}
                          separator=","
                          decimal="."
                          prefix=""
                          suffix={' ' + expenseStatus.status1Progress1name}
                        >
                          {({ countUpRef }) => (
                            <div className={'progress-label-child-1'} ref={countUpRef} />
                          )}
                        </CountUp>
                        <CountUp
                          delay={0}
                          start={0}
                          end={expenseStatus.status1Progress1total}
                          duration={2}
                          decimals={2}
                          separator=","
                          decimal="."
                          prefix={Utils.getCurrency()}
                          suffix=""
                        >
                          {({ countUpRef }) => (
                            <div className={'progress-label-child-2'} ref={countUpRef} />
                          )}
                        </CountUp>
                      </div>
                    </Tooltip>}
                  />}

                  {(expenseStatus.status1Progress2percent > 0) && <StyledProgressBarChild
                    now={expenseStatus.status1Progress2percent}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState(BillingStatusParams.isNotExpense, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'unbilled'}
                    label={<Tooltip
                      title={<div>
                        <div>{expenseStatus.status1Progress2count + ' ' + expenseStatus.status1Progress2name}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                        <CountUp
                          delay={0}
                          start={0}
                          end={expenseStatus.status1Progress2count}
                          duration={2}
                          decimals={0}
                          separator=","
                          decimal="."
                          prefix=""
                          suffix={' ' + expenseStatus.status1Progress2name}
                        >
                          {({ countUpRef }) => (
                            <div className={'progress-label-child-1'} ref={countUpRef} />
                          )}
                        </CountUp>
                      </div>
                    </Tooltip>}
                  />}
                </StyledProgressBar>
              </Col>
            </Row>
            :
            <Row className={'gy-5'}>
              <Col xs={12}>
                <StyledStatusTitle>&nbsp;</StyledStatusTitle>

                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    striped
                    animated
                    variant={'nonBillable'}
                    label={<div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{this.state.statusLodaingText}</div>
                    </div>}
                  />
                </StyledProgressBar>
              </Col>
            </Row>
          }

        </Col>

        <Col xs={12} className={'d-block d-sm-none'}>
          <Row>

            {
              !expenseStatus.isLoading
              ?
              <Col xs={12} className={'mb-2'}>
                <StyledStatusTitle
                  onClick={() => {
                    if(!this.props.checkboxCount.isSelected){
                      this.setState(BillingStatusParams.All, async () => {
                        await this.props.action.tableSection.tableChangeCurrentPage(1);
                        await this.props.action.tableSection.tableSetFilters({
                          auth: this.props.auth,
                          settingsType: this.props.settingsType,
                          pageName: this.pageName,
                        });
                      
                        this.callReadWithoutStatusesApi();
                      });
                    }
                  }}
                >{expenseStatus.status1total + '  + TOTAL JOBS'}</StyledStatusTitle>
              </Col>
              :
              <Col xs={12} className={'mb-2'}>
                <StyledStatusTitle>&nbsp;</StyledStatusTitle>
              </Col>
            }

            {
              !expenseStatus.isLoading
              ?
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState(BillingStatusParams.isExpensed, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'paid'}
                    label={<Tooltip
                      title={<div>
                        <div>{expenseStatus.status1Progress1count + ' ' + expenseStatus.status1Progress1name}</div>
                        <div>{Utils.getCurrency() + ' ' + Utils.formatNumber(expenseStatus.status1Progress1total)}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{expenseStatus.status1Progress1count + ' ' + expenseStatus.status1Progress1name}</div>
                      <div className={'progress-label-child-2'}>{Utils.getCurrency() + ' ' + Utils.formatNumber(expenseStatus.status1Progress1total)}</div>
                    </div>
                    </Tooltip>}
                  />
                </StyledProgressBar>
              </Col>
              :
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    striped
                    animated
                    variant={'nonBillable'}
                    label={<div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{this.state.statusLodaingText}</div>
                    </div>}
                  />
                </StyledProgressBar>
              </Col>
            }

            {
              !expenseStatus.isLoading
              ?
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    onClick={() => {
                      if(!this.props.checkboxCount.isSelected){
                        this.setState(BillingStatusParams.isNotExpense, async () => {
                          await this.props.action.tableSection.tableChangeCurrentPage(1);
                          await this.props.action.tableSection.tableSetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                          });
                        
                          this.callReadWithoutStatusesApi();
                        });
                      }
                    }}
                    variant={'unbilled'}
                    label={<Tooltip
                      title={<div>
                        <div>{expenseStatus.status1Progress2count + ' ' + expenseStatus.status1Progress2name}</div>
                      </div>}
                    >
                      <div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{expenseStatus.status1Progress2count + ' ' + expenseStatus.status1Progress2name}</div>
                    </div>
                    </Tooltip>}
                  />
                </StyledProgressBar>
              </Col>
              :
              <Col xs={12} className={'mb-2'}>
                <StyledProgressBar>
                  <StyledProgressBarChild
                    now={100}
                    striped
                    animated
                    variant={'nonBillable'}
                    label={<div className={'progress-label-parent'}>
                      <div className={'progress-label-child-1'}>{this.state.statusLodaingText}</div>
                    </div>}
                  />
                </StyledProgressBar>
              </Col>
            }

          </Row>
        </Col>

      </Row>
    }
    /* END STATUS */


    /* TABLE */
    tableSection = () => {
        return <DataTableScrollSync ref={this.refDataTableScrollSync} className={"table-sync-v4"}>{this.setDataTable()}</DataTableScrollSync>
    }
    setDataTable = () => {
        return <DataTable
            ref={this.tableRef}

            className={"table-style-v4"}
            classNameWrapper={"table-v4"}

            pageName={this.pageName}
            // rowId={this.pageIdField}
            rowId={'customerId'}
            caption={this.pageCaption}

            noMarginBottom={false}
            verticalAlign={'middle'}

            stickyHeader={false}
            isHeader={true}
            resizable={true}
            isDragableHeaderColumns={true}
            isDragable={false}
            isCheckbox={false}
            isBorder={false}
            isPagination={false}
            // isVirtualize={false}

            columnVisibility={this.props.tableSection.columnVisibility}

            isLoading={this.state.isLoadingTable}
            rowsPerPageSelected={this.props.tableSection.rowsPerPageSelected}
            rowsPerPageOptions={this.props.tableSection.rowsPerPageOptions}

            search={this.props.tableSection.search}
            rows={this.props.tableSection.rows}
            columns={this.props.tableSection.columns}
            details={this.setCustomDetails}

            onColumnClick={async (id, row, col, obj, index, field, value) => {
              if(field === 'unbilledActive'){
                if(obj.isRowExpanded(row.customerId)){
                  if(!this.checkCollapseExpand(row, field, value)){
                    this.collapseRow(row, obj)
                  } else {
                    await this.collapseRow(row, obj)
                    this.expandRow(index, field, row, obj)

                    this.setState(BillingStatusParams.Unbilled, () => {
                      obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.Unbilled);
                      // obj.collapseAllRows(() => {
                      //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.Unbilled);
                      // });
                    })
                  }

                } else {
                  this.expandRow(index, field, row, obj)

                  this.setState(BillingStatusParams.Unbilled, () => {
                    obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.Unbilled);
                    // obj.collapseAllRows(() => {
                    //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.Unbilled);
                    // });
                  })
                }

              } else if(field === 'billedActive'){
                if(obj.isRowExpanded(row.customerId)){
                  if(!this.checkCollapseExpand(row, field, value)){
                    this.collapseRow(row, obj)
                  } else {
                    await this.collapseRow(row, obj)
                    this.expandRow(index, field, row, obj)

                    this.setState(BillingStatusParams.Billed, () => {
                      obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.Billed);
                      // obj.collapseAllRows(() => {
                      //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.Billed);
                      // });
                    })
                  }

                } else {
                  this.expandRow(index, field, row, obj)

                  this.setState(BillingStatusParams.Billed, () => {
                    obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.Billed);
                    // obj.collapseAllRows(() => {
                    //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.Billed);
                    // });
                  })
                }

              } else if(field === 'nonBillableActive'){
                if(obj.isRowExpanded(row.customerId)){
                  if(!this.checkCollapseExpand(row, field, value)){
                    this.collapseRow(row, obj)
                  } else {
                    await this.collapseRow(row, obj)
                    this.expandRow(index, field, row, obj)

                    this.setState(BillingStatusParams.NonBillable, () => {
                      obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.NonBillable);
                      // obj.collapseAllRows(() => {
                      //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.NonBillable);
                      // });
                    })
                  }

                } else {
                  this.expandRow(index, field, row, obj)

                  this.setState(BillingStatusParams.NonBillable, () => {
                    obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.NonBillable);
                    // obj.collapseAllRows(() => {
                    //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.NonBillable);
                    // });
                  })
                }

              } else if(field === 'unbilledPurchaseActive'){
                if(obj.isRowExpanded(row.customerId)){
                  if(!this.checkCollapseExpand(row, field, value)){
                    this.collapseRow(row, obj)
                  } else {
                    await this.collapseRow(row, obj)
                    this.expandRow(index, field, row, obj)

                    this.setState(BillingStatusParams.Unbilled, () => {
                      obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.UnbilledPurchase);
                      // obj.collapseAllRows(() => {
                      //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.UnbilledPurchase);
                      // });
                    })
                  }

                } else {
                  this.expandRow(index, field, row, obj)

                  this.setState(BillingStatusParams.Unbilled, () => {
                    obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.UnbilledPurchase);
                    // obj.collapseAllRows(() => {
                    //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.UnbilledPurchase);
                    // });
                  })
                }

              } else if(field === 'billedPurchaseActive'){
                if(obj.isRowExpanded(row.customerId)){
                  if(!this.checkCollapseExpand(row, field, value)){
                    this.collapseRow(row, obj)
                  } else {
                    await this.collapseRow(row, obj)
                    this.expandRow(index, field, row, obj)

                    this.setState(BillingStatusParams.Billed, () => {
                      obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.BilledPurchase);
                      // obj.collapseAllRows(() => {
                      //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.BilledPurchase);
                      // });
                    })
                  }

                } else {
                  this.expandRow(index, field, row, obj)

                  this.setState(BillingStatusParams.Billed, () => {
                    obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.BilledPurchase);
                    // obj.collapseAllRows(() => {
                    //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.BilledPurchase);
                    // });
                  })
                }

              } else if(field === 'nonBillablePurchaseActive'){
                if(obj.isRowExpanded(row.customerId)){
                  if(!this.checkCollapseExpand(row, field, value)){
                    this.collapseRow(row, obj)
                  } else {
                    await this.collapseRow(row, obj)
                    this.expandRow(index, field, row, obj)

                    this.setState(BillingStatusParams.NonBillable, () => {
                      obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.NonBillablePurchase);
                      // obj.collapseAllRows(() => {
                      //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.NonBillablePurchase);
                      // });
                    })
                  }

                } else {
                  this.expandRow(index, field, row, obj)

                  this.setState(BillingStatusParams.NonBillable, () => {
                    obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.NonBillablePurchase);
                    // obj.collapseAllRows(() => {
                    //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.NonBillablePurchase);
                    // });
                  })
                }
              
              } else if(field === 'unbilledExpenseActive'){
                if(obj.isRowExpanded(row.customerId)){
                  if(!this.checkCollapseExpand(row, field, value)){
                    this.collapseRow(row, obj)
                  } else {
                    await this.collapseRow(row, obj)
                    this.expandRow(index, field, row, obj)

                    this.setState(BillingStatusParams.Unbilled, () => {
                      obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.UnbilledExpense);
                      // obj.collapseAllRows(() => {
                      //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.UnbilledExpense);
                      // });
                    })
                  }

                } else {
                  this.expandRow(index, field, row, obj)

                  this.setState(BillingStatusParams.Unbilled, () => {
                    obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.UnbilledExpense);
                    // obj.collapseAllRows(() => {
                    //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.UnbilledExpense);
                    // });
                  })
                }

              } else if(field === 'billedExpenseActive'){
                if(obj.isRowExpanded(row.customerId)){
                  if(!this.checkCollapseExpand(row, field, value)){
                    this.collapseRow(row, obj)
                  } else {
                    await this.collapseRow(row, obj)
                    this.expandRow(index, field, row, obj)

                    this.setState(BillingStatusParams.Billed, () => {
                      obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.BilledExpense);
                      // obj.collapseAllRows(() => {
                      //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.BilledExpense);
                      // });
                    })
                  }

                } else {
                  this.expandRow(index, field, row, obj)

                  this.setState(BillingStatusParams.Billed, () => {
                    obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.BilledExpense);
                    // obj.collapseAllRows(() => {
                    //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.BilledExpense);
                    // });
                  })
                }

              } else if(field === 'nonBillableExpenseActive'){
                if(obj.isRowExpanded(row.customerId)){
                  if(!this.checkCollapseExpand(row, field, value)){
                    this.collapseRow(row, obj)
                  } else {
                    await this.collapseRow(row, obj)
                    this.expandRow(index, field, row, obj)

                    this.setState(BillingStatusParams.NonBillable, () => {
                      obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.NonBillableExpense);
                      // obj.collapseAllRows(() => {
                      //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.NonBillableExpense);
                      // });
                    })
                  }

                } else {
                  this.expandRow(index, field, row, obj)

                  this.setState(BillingStatusParams.NonBillable, () => {
                    obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.NonBillableExpense);
                    // obj.collapseAllRows(() => {
                    //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.NonBillableExpense);
                    // });
                  })
                }

              } else if(field === 'paidActive'){
                if(obj.isRowExpanded(row.customerId)){
                  if(!this.checkCollapseExpand(row, field, value)){
                    this.collapseRow(row, obj)
                  } else {
                    await this.collapseRow(row, obj)
                    this.expandRow(index, field, row, obj)

                    this.setState(BillingStatusParams.Paid, () => {
                      obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.Paid);
                      // obj.collapseAllRows(() => {
                      //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.Paid);
                      // });
                    })
                  }

                } else {
                  this.expandRow(index, field, row, obj)

                  this.setState(BillingStatusParams.Paid, () => {
                    obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.Paid);
                    // obj.collapseAllRows(() => {
                    //   obj.handleRowClickProgramaticly(row.customerId, row, BillingStatusParams.Paid);
                    // });
                  })
                }
              }
            }}

            onRowClick={async (id, row, obj, value) => {
              let isExpanded = obj.isRowExpanded(row.customerId);
              // obj.expandSingleRow(row.customerId);
              obj.expandRow(row.customerId);

              let rowIndex = -1;
              let rows = _.cloneDeep(this.props.tableSection.rows);
              if(rows && rows.length > 0){
                rowIndex = rows.findIndex(x => x.customerId === row.customerId);
              }

              if(!isExpanded){
                Utils.scrollTo('row_' + row.customerId, -187, 500);

                if(rowIndex > -1){
                  rows[rowIndex].isLoading = true;
                  await this.props.action.tableSection.tableChangeRows(rows);
                }

                let statusValue = (value) ? value : this.state.statsFilter ? this.state.statsFilter : BillingStatusParams.All;
                await this.setState(statusValue);
                await this.callCustomerSitesApi(row.customerId);
                if(rowIndex > -1){
                  let rows = _.cloneDeep(this.props.tableSection.rows);

                  rows[rowIndex].isLoading = false;
                  rows[rowIndex].details = this.props.billing.customerSites.items;
                  rows[rowIndex].fromDate = this.props.dateSection.fromDate;
                  rows[rowIndex].toDate = this.props.dateSection.toDate;
                  rows[rowIndex].statusValue = value;

                  rows.forEach((itm, ii) => {
                    if(ii != rowIndex){
                      itm['unbilledActive'] = false;
                      itm['billedActive'] = false;
                      itm['nonBillableActive'] = false;
                      itm['unbilledPurchaseActive'] = false;
                      itm['billedPurchaseActive'] = false;
                      itm['nonBillablePurchaseActive'] = false;
                      itm['unbilledExpenseActive'] = false;
                      itm['billedExpenseActive'] = false;
                      itm['nonBillableExpenseActive'] = false;
                      itm['paidActive'] = false;
                    }
                  });

                  await this.props.action.tableSection.tableChangeRows(rows);
                  // obj.refrsh();
                }
              } else {
                try {
                  if(this.tableRef && this.tableRef.current){
                    this.tableRef.current.setSelected();
                  }
                  this.reselctAllCB();
                  this.props.action.checkboxCount.clear();
                }catch(e){}

                if(rowIndex > -1){
                  let rows = _.cloneDeep(this.props.tableSection.rows);

                  rows[rowIndex].isLoading = false;
                  rows[rowIndex].details = null;
                  rows[rowIndex].fromDate = this.props.dateSection.fromDate;
                  rows[rowIndex].toDate = this.props.dateSection.toDate;
                  rows[rowIndex].statusValue = null;
                  
                  rows.forEach((itm) => {
                    itm['unbilledActive'] = false;
                    itm['billedActive'] = false;
                    itm['nonBillableActive'] = false;
                    itm['unbilledPurchaseActive'] = false;
                    itm['billedPurchaseActive'] = false;
                    itm['nonBillablePurchaseActive'] = false;
                    itm['unbilledExpenseActive'] = false;
                    itm['billedExpenseActive'] = false;
                    itm['nonBillableExpenseActive'] = false;
                    itm['paidActive'] = false;
                  });

                  await this.props.action.tableSection.tableChangeRows(rows);
                  obj.refrsh();
                }
              }
            }}

            onRemove={(row) => {

            }}

            onClickCheckAll={async (obj, checked, isDisabledBatchAction) => {
              
            }}
            onCheckRow={async (obj, id, isDisabledBatchAction) => {
              
            }}

            onSortEnd={async (columns, columnVisibility) => {
              await this.props.action.tableSection.tableSetFilters({
                auth: this.props.auth,
                settingsType: this.props.settingsType,
                pageName: this.pageName,

                columns: columns,
                columnVisibility: columnVisibility,
              });
            }}
            onOrder={async (name, sort) => {
              await this.props.action.tableSection.tableSetFilters({
                auth: this.props.auth,
                settingsType: this.props.settingsType,
                pageName: this.pageName,

                sortColumn: name,
                sortDir: sort,
              });
              this.callReadWithoutStatusesApi();
            }}
            onResize={(columns) => {
              if(columns){
                this.props.action.tableSection.tableSetFilters({
                  auth: this.props.auth,
                  settingsType: this.props.settingsType,
                  pageName: this.pageName,
                  
                  columns: columns,
                });
              }
            }}
        />
    }
    setCustomDetails = (row, props, isItemSelected, obj) => {
      let colSpan = this.props.tableSection.columnVisibility.filter(x => x === true).length;
  
      let isExpanded = false;
      if(this.tableRef && this.tableRef.current){
        isExpanded = this.tableRef.current.isRowExpanded(row.customerId);
      }

      let jobStatusFilter = (this.props.jobStatuses.selectedIds && this.props.jobStatuses.selectedIds.length > 0) ? this.props.jobStatuses.selectedIds.join(',') : null;
      
      if(isExpanded){
        return <TableRow 
          className={'details-row custom-details-row-' + row.customerId + (isExpanded ? ' exp-details-row exp-details-color ' : '') + (row.isLoading ? ' is-loading' : '')}
        >
          <TableCell colSpan={(colSpan)}>
            <div className={'ps-2 pe-2 pt-3 pb-3'}>
              {
                row.isLoading
                ?
                <div style={{ width: (window.innerWidth - 150) + 'px', textAlign: 'center' }}>
                  <Spinner animation="border" />
                </div>
                :
                <GroupByCustomerDetails 
                  ref={this.selectedTableRef}
                  rowData={row}
                  details={row.details}
                  jobStatusFilter={jobStatusFilter}
                  type={this.state.billingType}
                  isBillable={this.state.isBillable}
                  isInvoiced={this.state.isInvoiced}
                  isPaid={this.state.isPaid}
                  onRowClick={(id, row, ref) => {
                    this.drawerObjRef.current = ref;
                   
                    let dataParams = {
                      id: id,
                      row: row,
                    };
                    Utils.setDrawerData(this.rowDrawer, dataParams);
                    Utils.showDrawer(this.rowDrawer);
                  }}
                  onDisabledBatchAction={async(isDisabledBatchAction, ref, selectedCount) => {
                    await this.props.action.tableSection.tableChangeDisabledBatchAction(isDisabledBatchAction);
                    if(selectedCount){
                      await this.props.action.tableSection.tableChangeSelected(selectedCount);
                    }
                  }}
                  onSelectedCount={async(selectedCount) => {
                    await this.props.action.tableSection.tableChangeSelected(selectedCount);
                  }}
                  onChangeBillingAddress={(customerId, billingAddress, refs) => {
                    this.callBillingAddressUpdateApi(customerId, billingAddress, () => {
                      if(this.drawerObjRef && this.drawerObjRef.current){
                        this.drawerObjRef && this.drawerObjRef.current.onRealod()
                      }
                    });
                  }}
                  onRefAdd={(id, ref) => {
                    if (!this.tableRefs.some(x => x.id === id)) {
                      this.tableRefs.push(ref);
                    } else {
                      let index = this.tableRefs.findIndex(x => x.id === id);
                      if(index > -1){
                        this.tableRefs[index] = ref;
                      }
                    }
                  }}
                />
              }
            </div>
          </TableCell>
        </TableRow>
      }
    }
    paginationSection = () => {
        return <DataTableAjaxPagination
            style={{
                padding: '10px 0px',
                border: '0px',
            }}
            page={this.props.tableSection.currentPage}
            selected={this.props.tableSection.rowsPerPageSelected}
            count={this.props.tableSection.totalRows}
            fixed={true}
            onPageChange={async (page) => {
              await this.props.action.tableSection.tableChangeCurrentPage(page);
              await this.props.action.tableSection.tableSetFilters({
                auth: this.props.auth,
                settingsType: this.props.settingsType,
                pageName: this.pageName,
              });
              this.callReadApi();
            }}
            onOptionsChange={async (option) => {
              await this.props.action.tableSection.tableChangeRowsPerPageSelected(option);
              await this.props.action.tableSection.tableSetFilters({
                auth: this.props.auth,
                settingsType: this.props.settingsType,
                pageName: this.pageName,
              });
              this.callReadApi();
            }}
        />
    }
    /* END TABLE */


    /* DIALOG */
    rowDrawerSection = () => {
      return <DrawerLayout
        name={'row'}
        ref={this.refRowDrawerForm}
        drawer={this.rowDrawer}
        validationSchema={rowSchema}
        initialValues={this.state.rowInitialValues}
        values={this.state.rowValues}
        isVisible={this.state.isVisibleRowDrawer}
        isBlocking={Utils.isBlockingDrawer(this.state.isLoadingRowDrawer, this.state.isBlockingRowDrawerForm)}
        renderChildren={Utils.shouldDrawerRenderChildren(this.state.isLoadingRowDrawer, this.state.isBlockingRowDrawerForm)}
        size={'100%'}
  
        title={(data) => {
          if(data){
            return <Row className={'align-items-center'}>
              <Col xs={'auto'}>
                <div className={'d-flex align-items-center'}>
                  <span className={'pe-2'}>{data.row.customerName}</span>
                  {/* <IconButton
                    icon={'history'}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  /> */}
                </div>
                <div className={'d-flex align-items-center'}>
                  <small>
                    <a href={'/jobs-form?id=' + data.row.jobId}
                      target={'_blank'}
                      rel="noreferrer"
                      className={'d-flex align-items-center'}
                      onClick={(e) => {
                        // e.preventDefault();
                        e.stopPropagation();
                        
                        // this.props.history.push('/jobs-form?id=' + data.row.jobId);
                      }}
                    >
                      {data.row.jobNumber}
                      <i className={'material-icons text-primary ps-1'} style={{ fontSize: '19px' }}>launch</i>
                    </a>
                  </small>
                </div>
              </Col>
              <Col xs={'auto'}>
                {this.state.isBillableStatus && <IconButtonTooltip
                  title={'Update billing'}
                  placement={'bottom'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
  
                    if(this.refRowForm && this.refRowForm.current){
                      this.refRowForm.current.updateBilling();
                    }
                  }}
                >
                  <i className={'material-icons'}>refresh</i>
                </IconButtonTooltip>}
              </Col>
            </Row>
          }
        }}
        toolbar={null}
        
        onOpenClose={async (isOpen, dataParams) => {
          if(isOpen){
            await this.callJobBillingDetailsApi(dataParams.id);
            let rowValues = prepareForm(this.state.rowInitialValues, dataParams, this.props.billing.billingDetails.data, this.state.billingType, this.dispatchUser.defaultUoM);
            
            this.setState({
              isBillableStatus: rowValues ? rowValues.isBillable : this.state.isBillable,
              rowValues: rowValues,
              isBlockingRowDrawerForm: false,
            });
          }
        }}
        onSubmit={async (form, dataParams) => {
          let data = null;
          if(this.state.billingType == BillingTypes.Expense){
            data = prepareDataForExpenseOUTJobType(form, dataParams);
          } else {
            data = prepareData(form, dataParams);
          }
          await this.callSaveJobBillingApi(data);
          
          this.setState({
            isLoadingRowDrawer: false,
          }, async () => {
            if(this.props.billing.billingSave.isError){

            } else {
              Utils.colorRowsReset('.table-style-v5 > tbody > tr');

              try {
                if(this.tableRef && this.tableRef.current){
                  this.tableRef.current.setSelected();
                }
                this.reselctAllCB();
                this.props.action.checkboxCount.clear();
              }catch(e){}
              
              try {
                this.changeJobs([this.props.billing.billingSave.job]);
              }catch(e){}

              try {
                Utils.colorRowsPrimery([data.jobId]);
              }catch(e){}
              
              try {
                Utils.showDrawer(this.rowDrawer, false);
              }catch(e){}
            }
          });
        }}
      >
        {(formOptions, dataParams, isBlocking) => {
          return <RowDrawerForm
            ref={this.refRowForm}
            drawer={this.rowDrawer}
            fields={rowFields}
            initialValues={this.state.rowInitialValues}
            values={this.state.rowValues}
            formOptions={formOptions}
            dataParams={dataParams}
            isBlocking={isBlocking}
            billingType={this.state.billingType}
            onInvoiceThis={async (form, dataParams) => {
              let data = null;
              if(this.state.billingType == BillingTypes.Expense){
                data = prepareDataForExpenseOUTJobType(form, dataParams);
              } else {
                data = prepareData(form, dataParams);
              }
              this.props.action.invoiceThisJob.callPushInvoiceApi(data.jobId, data, this.state.billingType);
            }}
            onChangeStatus={async (isBillable) => {
              this.setState({
                isBillableStatus: isBillable,
              });
            }}
            setIsBlocking={async (isBlocking) => {
              this.setState({
                isLoadingRowDrawer: isBlocking,
              });
            }}
          />
        }}
      </DrawerLayout>
    }
    /* END DIALOG */


    render() {
      return <div>
        <ViewLayout
          isBlocking={
            this.props.billingBatchAction.updateBillingStatus.isLoading || 
            this.props.billingBatchAction.refreshBillingStatus.isLoading || 
            this.props.billingBatchAction.createInvoice.isLoading ||
            this.props.billingBatchAction.createPurchase.isLoading
          }
          topSection={this.topSection()}
          statusSection={(this.state.billingType == BillingTypes.Purchase) ? this.statusSectionPurchase() : (this.state.billingType == BillingTypes.Expense) ? this.statusSectionExpense() : this.statusSectionInvoice()}
          tableSection={this.tableSection()}
          paginationSection={this.paginationSection()}
        />
        {this.rowDrawerSection()}
        
        <InvoiceNumberPopup 
          billingType={this.state.billingType}
          onHide={async (status, allJobIds) => {
            if(status){
              const asyncTasks = [];

              asyncTasks.push(
                (async () => {
                  try {
                    if(this.tableRef && this.tableRef.current){
                      this.tableRef.current.setSelected();
                    }
                    this.reselctAllCB();
                  }catch(e){}
                })()
              );

              asyncTasks.push(
                (async () => {
                  try {
                    this.changeCustomers(allJobIds.customers);
                    this.changeJobs(allJobIds.successJobs);
                  }catch(e){}
                })()
              );

              Promise.all(asyncTasks)
                .then(() => {
                  this.callReadWithoutLoadingApi();
                })
                .catch((error) => {
                  this.callReadWithoutLoadingApi();
                });
            }
          }}
        />

        <InvoiceThisJobPopup
          slice={state => state.invoiceThisJob}
          onHide={() => {
            this.props.action.invoiceThisJob.hide();
          }}
          onPush={async (jobId, invoiceNumber, args) => {
            let data = _.cloneDeep(args);
            data.invoiceNumber = invoiceNumber
            
            await this.props.action.invoiceThisJob.callPushInvoiceApi(jobId, data, this.state.billingType);
          }}
        />
      </div>
    }
}

const mapStateToProps = state => {
  return {
    ...state,
    ...Utils.mapStateToProps(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  action: {
    dateSection: {
      dateGetFilters: (payload) => dispatch(dateSection.dateGetFilters(payload)),
      dateSetFilters: (payload) => dispatch(dateSection.dateSetFilters(payload)),
      dateNext: (payload) => dispatch(dateSection.dateNext(payload)),
      datePrev: (payload) => dispatch(dateSection.datePrev(payload)),
      dateToday: (payload) => dispatch(dateSection.dateToday(payload)),
      dateChange: (payload) => dispatch(dateSection.dateChange(payload)),
    },
    tableSection: {
      tableGetFilters: (payload) => dispatch(tableSection.tableGetFilters(payload)),
      tableSetFilters: (payload) => dispatch(tableSection.tableSetFilters(payload)),
      tableSearch: (payload) => dispatch(tableSection.tableSearch(payload)),
      tableChangeIncludeInactive: (payload) => dispatch(tableSection.tableChangeIncludeInactive(payload)),
      tableChangeInactive: (payload) => dispatch(tableSection.tableChangeInactive(payload)),
      tableChangeRowsPerPageSelected: (payload) => dispatch(tableSection.tableChangeRowsPerPageSelected(payload)),
      tableChangeCurrentPage: (payload) => dispatch(tableSection.tableChangeCurrentPage(payload)),
      tableChangeDisabledBatchAction: (payload) => dispatch(tableSection.tableChangeDisabledBatchAction(payload)),
      tableChangeSelected: (payload) => dispatch(tableSection.tableChangeSelected(payload)),
      tableChangeRows: (payload) => dispatch(tableSection.tableChangeRows(payload)),
      callTableReadApi: (payload) => dispatch(tableSection.callTableReadApi(payload)),
      callTableDownloadExcelApi: (payload) => dispatch(tableSection.callTableDownloadExcelApi(payload)),
    },
    jobStatuses: {
      callReadJobStatusesApi: (payload) => dispatch(jobStatuses.callReadJobStatusesApi(payload)),
      getIdByName: (payload) => dispatch(jobStatuses.getIdByName(payload)),
      getIdsByNames: (payload) => dispatch(jobStatuses.getIdsByNames(payload)),
    },
    billingDefault: {
      getDefault: (payload) => dispatch(billingDefault.getDefault(payload)),
      setDefault: (payload) => dispatch(billingDefault.setDefault(payload)),
    },
    billingStatus: {
      callJobBillingStatApi: (payload) => dispatch(billingInvoiceStatusSection.callJobBillingStatApi(payload)),
      callJobInvoiceStatApi: (payload) => dispatch(billingInvoiceStatusSection.callJobInvoiceStatApi(payload)),
      callPurchaseStatApi: (payload) => dispatch(billingInvoiceStatusSection.callPurchaseStatApi(payload)),
      callExpenseStatApi: (payload) => dispatch(billingInvoiceStatusSection.callExpenseStatApi(payload)),
    },
    billingQuickbookXero: {
      callQuickbookApi: (payload) => dispatch(billingQuickbookXero.callQuickbookApi(payload)),
      callXeroApi: (payload) => dispatch(billingQuickbookXero.callXeroApi(payload)),
      callQBPurchaseApi: (payload) => dispatch(billingQuickbookXero.callQBPurchaseApi(payload)),
      callQBExpenseApi: (payload) => dispatch(billingQuickbookXero.callQBExpenseApi(payload)),
    },
    billingBatchAction: {
      batchUpdateBillingStatus: (payload) => dispatch(billingBatchAction.batchUpdateBillingStatus(payload)),
      batchRefreshBillingStatus: (payload) => dispatch(billingBatchAction.batchRefreshBillingStatus(payload)),
    },
    billing: {
      callCustomerSitesApi: (payload) => dispatch(billing.callCustomerSitesApi(payload)),
      callJobBillingDetailsApi: (payload) => dispatch(billing.callJobBillingDetailsApi(payload)),
      callSaveJobBillingApi: (payload) => dispatch(billing.callSaveJobBillingApi(payload)),
      callBillingAddressUpdateApi: (payload) => dispatch(billing.callBillingAddressUpdateApi(payload)),
    },
    invoiceNumber: {
      show: (payload) => dispatch(invoiceNumber.show(payload)),
      callJobsApi: (payload) => dispatch(invoiceNumber.callJobsApi(payload)),
    },
    invoiceNumberForm: {
      callReadBillingProfileApi: (payload) => dispatch(invoiceNumberForm.callReadBillingProfileApi(payload)),
    },
    invoiceThisJob: {
      hide: (payload) => dispatch(invoiceThisJob.hide(payload)),
      callPushInvoiceApi: (jobId, data, billingType) => dispatch(invoiceThisJob.callPushInvoiceApi(jobId, data, billingType)),
    },
    checkboxCount: {
      clear: (payload) => dispatch(checkboxCount.clear(payload)),
    },
    signalRslice: {
      connect: (payload) => dispatch(signalRslice.connect(payload)),
      disconnect: (payload) => dispatch(signalRslice.disconnect(payload)),
      onPercentage: (payload) => dispatch(signalRslice.onPercentage(payload)),
    },
    cancelToken: {
      cancel: (payload) => dispatch(cancelToken.cancel(payload)),
    },
  }
});


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BillingInvoiceListByCustomer);

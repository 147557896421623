import React from 'react';
import _ from 'lodash';
import h2p from 'html2plaintext';

import { 
    Row,
    Col,
    Form,
    InputGroup,
    Dropdown,
    Button,
    Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { Formik, FieldArray } from 'formik';

import { useDispatch, useSelector } from "react-redux";
import invoiceNumber from '../../redux/invoiceNumber';
import invoiceNumberForm from '../../redux/invoiceNumberForm';
import Utils from '../../../../utils/utils';
import { BillingTypes, XeroPaymentTermTypeItems, XeroPaymentTermType } from '../../../../utils/enums';
import { StyledDropdownButton } from "../../../../utils/styles";

import RSuiteSelectPicker from '../../../../components/OLD/Select/RSuiteSelectPicker';
import RSuiteDatePicker from '../../../../components/OLD/Datepicker/RSuiteDatePicker';
import Tooltip from '../../../../components/Tooltip/Tooltip';


const StyledWarning = styled.span`
    color: #FFA200;
`;

const StyledHr = styled.hr`
    border-color: #a1a1a1;
`;

const StyledX = styled.a`
    i {
        font-size: 16px;
        color: #CF3E3E;
        position: relative;
        top: 4px;
    }

    &:hover {
        opacity: 0.5
    }
`;

const StyledCheck = styled(Form.Check)`
    min-height: auto;
    line-height: 15px;
    
    .form-check-input {
        width: 16px;
        height: 16px;
        border-radius: 0px;
    }
`;


const TabForm = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { isLoading, selectedTab, status, items, failedItems, billingType, weightChitJobIds } = useSelector((state) => state.invoiceNumber);
    const { formValues, formLabels, validationSchema, enableReinitialize, validateOnMount, validateOnChange, billingProfile, creditTerms, resetInvoiceNumber } = useSelector((state) => state.invoiceNumberForm);

    const name = status ? 'failed' : 'create';

    const formikRef = React.useRef();

    const [count, setCount] = React.useState(0);


    React.useImperativeHandle(
        ref,
        () => ({
            submitForm() {
                if (formikRef.current) {
                    formikRef.current.handleSubmit();
                }
            },
            getForm() {
                let form = [];
                if (formikRef.current) {
                    form = (formikRef.current.values && formikRef.current.values.form && formikRef.current.values.form.length > 0) ? formikRef.current.values.form : [];
                }
                return form;
            },
         }),
    );

    React.useEffect(() => {
        dispatch(invoiceNumberForm.initForm((selectedTab == 1) ? items : failedItems ))

        // if(props.onCountChange){
        //     props.onCountChange(getCount(failedItems));
        // }
    }, [selectedTab, items, failedItems]);

    React.useEffect(() => {
        getCount(failedItems);
    }, []);

    React.useEffect(() => {
        getCount(failedItems);
    }, [failedItems]);

    React.useEffect(() => {
        if(props.onCountChange){
            props.onCountChange(count);
        }
    }, [count]);


    const isCheckedJob = (customerSiteId, jobId) => {
        let item = weightChitJobIds.find(x => x.customerSiteId == customerSiteId)
        if(item){
            if(item.jobs && item.jobs.length > 0){
                let index = item.jobs.findIndex(x => x == jobId)
                if(index > -1){
                    return true
                }
            }
        }

        return false
    }
    const isCheckedAllJobs = (site) => {
        if(site){
            let ids = getAllJobsBySiteId(site);
    
            let item = weightChitJobIds.find(x => x.customerSiteId == site.customerSiteId)
            if(item && item.jobs && item.jobs.length > 0){
                return ids.every(id => item.jobs.includes(id));
            }
        }

        return false
    }
    const getAllJobsBySiteId = (site) => {
        let ids = [];

        if(site && site.jobs && site.jobs.length > 0){
            ids = site.jobs.filter(x => x.haveWeightChit == true).map(x => x.jobId);
        }
        
        return ids
    }
    
    const getCount = (items) => {
        let total = 0;

        //Job count
        // if(items && items.length > 0){
        //     items.forEach((customer) => {
        //         if(customer && customer.siteJobs && customer.siteJobs.length > 0){
        //             customer.siteJobs.forEach((site) => {
        //                 let count = (site && site.jobs && site.jobs.length > 0) ? site.jobs.length : 0;
        //                 total = total + count
        //             })
        //         }
        //     });
        // }

        //Customer count
        total = (items && items.length > 0) ? items.length : 0;

        setCount(total);

        return total;
    }

    const customerForm = (formOptions, item, i) => {
        let {
            setFieldValue,
            errors,
        } = formOptions;

        return <>
            <Col xs={12} className={'mb-3 px-5 px-md-10'}>
                <Row className={'mt-2 mb-3'}>
                    <Col xs={12} md={3}>
                        <Form.Group>
                            <Form.Control as={'div'} className={'mb-0'} plaintext readOnly>
                                {formLabels.customerName.label}
                                {formLabels.customerName.required && <span className={'text-danger ps-1'}>*</span>}
                            </Form.Control>
                            <Form.Control
                                {...formLabels.customerName.field}
                                isInvalid={!!(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer[formLabels.customerName.field.id])}
                                readOnly={(status === true) ? (selectedTab == 1) : !item.siteJobs.some(site => site.jobs.some(x => x.isBillable))}
                                
                                value={item.customer[formLabels.customerName.field.id]}
                                onChange={async (e) => {
                                    const { name, value } = e.target;
                                    setFieldValue('form.' + i + '.customer.' + name, value);

                                    if(
                                        (item.customer[formLabels.billingName.field.id] == undefined || item.customer[formLabels.billingName.field.id] == null || item.customer[formLabels.billingName.field.id] == '') ||
                                        (item.customer[formLabels.customerName.field.id] == item.customer[formLabels.billingName.field.id])
                                    ){
                                        setFieldValue('form.' + i + '.customer.billingName', value);
                                    }
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer[formLabels.customerName.field.id]) && errors.form[i].customer[formLabels.customerName.field.id]}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group>
                            <Form.Control as={'div'} className={'mb-0'} plaintext readOnly>
                                {formLabels.billingName.label}
                                {formLabels.billingName.required && <span className={'text-danger ps-1'}>*</span>}
                            </Form.Control>
                            <Form.Control
                                {...formLabels.billingName.field}
                                isInvalid={!!(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer[formLabels.billingName.field.id])}
                                readOnly={(status === true) ? (selectedTab == 1) : !item.siteJobs.some(site => site.jobs.some(x => x.isBillable))}
                                
                                value={item.customer[formLabels.billingName.field.id]}
                                onChange={async (e) => {
                                    const { name, value } = e.target;
                                    setFieldValue('form.' + i + '.customer.' + name, value);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer[formLabels.billingName.field.id]) && errors.form[i].customer[formLabels.billingName.field.id]}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </Col>

            <Col xs={12} className={'mb-3 px-5 px-md-10'}>
                <Row className={'mt-2 mb-3'}>
                    <Col xs={12} lg={item.customer.billingProfile.isConnectXero ? 2 : 4} xl={3}>
                        <Form.Group>
                            <Form.Control as={'div'} className={'mb-0'} plaintext readOnly>
                                {formLabels.billingAddress.label}
                                {formLabels.billingAddress.required && <span className={'text-danger ps-1'}>*</span>}
                            </Form.Control>
                            <Form.Control
                                {...formLabels.billingAddress.field}
                                isInvalid={!!(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer[formLabels.billingAddress.field.id])}
                                readOnly={(status === true) ? (selectedTab == 1) : !item.siteJobs.some(site => site.jobs.some(x => x.isBillable))}
                                
                                value={item.customer[formLabels.billingAddress.field.id]}
                                onChange={async (e) => {
                                    Utils.limitRowsBillingAddress(e.target.value, formLabels.billingAddress.field.rows, async (v) => {
                                        const { name, value } = e.target;
                                        setFieldValue('form.' + i + '.customer.' + name, v);
                                    });
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer[formLabels.billingAddress.field.id]) && errors.form[i].customer[formLabels.billingAddress.field.id]}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={2}>
                        <Form.Group>
                            <Form.Control as={'div'} className={'mb-0'} plaintext readOnly>
                                {formLabels.accountBillingProfileId.label}
                                {formLabels.accountBillingProfileId.required && <span className={'text-danger ps-1'}>*</span>}
                            </Form.Control>
                            <Form.Control
                                {...formLabels.accountBillingProfileId.field}
                                isInvalid={!!(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer[formLabels.accountBillingProfileId.field.id])}
                                readOnly={(status === true) ? (selectedTab == 1) : !item.siteJobs.some(site => site.jobs.some(x => x.isBillable))}

                                as = {RSuiteSelectPicker}
                                items={billingProfile.data}
                                isLoading={billingProfile.isLoading}
                                valueKey={formLabels.accountBillingProfileId.field.id}
                                labelKey={formLabels.accountBillingProfileId.field.name}
                                searchable={true}
                                cleanable={true}
                                isDefault={true}
                                onClick={() => {
                                    dispatch(invoiceNumberForm.callReadBillingProfileApi())
                                }}
                                onClear={() => {
                                    setFieldValue('form.' + i + '.customer.' + formLabels.accountBillingProfileId.field.id, null);
                                    setFieldValue('form.' + i + '.customer.' + formLabels.accountBillingProfileId.field.name, '');

                                    setFieldValue('form.' + i + '.customer.' + formLabels.xeroPaymentTerm.field.name, '');
                                    setFieldValue('form.' + i + '.customer.' + formLabels.xeroPaymentTermType.field.name, '0');

                                    setFieldValue('form.' + i + '.customer.billingProfile.quickBookApp.quickBookAppId', 0);
                                    setFieldValue('form.' + i + '.customer.billingProfile.isConnectQuickbook', false);
                                    setFieldValue('form.' + i + '.customer.billingProfile.isConnectXero', false);
                                    setFieldValue('form.' + i + '.customer.billingProfile.invoiceNumberPrefix', '');
                                    setFieldValue('form.' + i + '.customer.billingProfile.purchaseOrderNumberPrefix', '');
                                    setFieldValue('form.' + i + '.customer.billingProfile.expenseNumberPrefix', '');
                                    setFieldValue('form.' + i + '.customer.billingProfile.invoiceNumber', '');
                                }}

                                value={item.customer[formLabels.accountBillingProfileId.field.id]}
                                selectedName={item.customer[formLabels.accountBillingProfileId.field.name]}
                                onSelect={(value, item) => {
                                    let invoiceNumberPrefix = '';
                                    let purchaseOrderNumberPrefix = '';
                                    let expenseNumberPrefix = '';
                                    if(billingType == BillingTypes.Purchase){
                                        purchaseOrderNumberPrefix = (item && item.purchaseOrderNumberPrefix) ? item.purchaseOrderNumberPrefix : '';
                                    } else if(billingType == BillingTypes.Expense){
                                        expenseNumberPrefix = (item && item.expenseNumberPrefix) ? item.expenseNumberPrefix : '';
                                    } else {
                                        invoiceNumberPrefix = (item && item.invoiceNumberPrefix) ? item.invoiceNumberPrefix : '';
                                    }

                                    let isConnectQuickbook = (item && item.isConnectQuickbook) ? item.isConnectQuickbook : false;
                                    let isConnectXero = (item && item.isConnectXero) ? item.isConnectXero : false;

                                    let quickBookApp = (item && item.quickBookApp) ? item.quickBookApp : null;
                                    let xeroApp = (item && item.xeroApp) ? item.xeroApp : null;

                                    if(isConnectXero){
                                        let xeroPaymentTerm = (xeroApp && xeroApp.xeroPaymentTerm) ? xeroApp.xeroPaymentTerm.toString() : '0';
                                        let xeroPaymentTermType = (xeroApp && xeroApp.xeroPaymentTermType) ? xeroApp.xeroPaymentTermType.toString() : '0';
                                        
                                        setFieldValue('form.' + i + '.customer.' + formLabels.xeroPaymentTerm.field.name, xeroPaymentTerm);
                                        setFieldValue('form.' + i + '.customer.' + formLabels.xeroPaymentTermType.field.name, xeroPaymentTermType);
                                    }

                                    setFieldValue('form.' + i + '.customer.' + formLabels.accountBillingProfileId.field.id, value);
                                    setFieldValue('form.' + i + '.customer.' + formLabels.accountBillingProfileId.field.name, item[formLabels.accountBillingProfileId.field.name]);
                                    
                                    setFieldValue('form.' + i + '.customer.billingProfile.quickBookApp', quickBookApp);
                                    setFieldValue('form.' + i + '.customer.billingProfile.xeroApp', xeroApp);
                                    setFieldValue('form.' + i + '.customer.billingProfile.isConnectQuickbook', isConnectQuickbook);
                                    setFieldValue('form.' + i + '.customer.billingProfile.isConnectXero', isConnectXero);
                                    setFieldValue('form.' + i + '.customer.billingProfile.invoiceNumberPrefix', invoiceNumberPrefix);
                                    setFieldValue('form.' + i + '.customer.billingProfile.purchaseOrderNumberPrefix', purchaseOrderNumberPrefix);
                                    setFieldValue('form.' + i + '.customer.billingProfile.expenseNumberPrefix', expenseNumberPrefix);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer[formLabels.accountBillingProfileId.field.id]) && errors.form[i].customer[formLabels.accountBillingProfileId.field.id]}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} lg={2}>
                        <Form.Group>
                            <Form.Control as={'div'} className={'mb-0'} plaintext readOnly>
                                {(billingType == BillingTypes.Purchase) ? formLabels.invoiceDate.labelPurchase : (billingType == BillingTypes.Expense) ? formLabels.invoiceDate.labelExpense : formLabels.invoiceDate.label}
                                {formLabels.invoiceDate.required && <span className={'text-danger ps-1'}>*</span>}
                            </Form.Control>
                            <Form.Control
                                {...formLabels.invoiceDate.field}
                                isInvalid={!!(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer.billingProfile && errors.form[i].customer.billingProfile[formLabels.invoiceDate.field.id])}
                                readOnly={(status === true) ? (selectedTab == 1) : !item.siteJobs.some(site => site.jobs.some(x => x.isBillable))}

                                as = {RSuiteDatePicker}
                                icon={<></>}
                                placement={'bottom'}
                                cleanable={true}
                                isDefault={true}
                                onClear={() => {
                                    setFieldValue('form.' + i + '.customer.billingProfile.' + formLabels.invoiceDate.field.id, null);
                                }}

                                value={item.customer.billingProfile[formLabels.invoiceDate.field.id]}
                                onChange={(value) => {
                                    setFieldValue('form.' + i + '.customer.billingProfile.' + formLabels.invoiceDate.field.id, value);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer.billingProfile && errors.form[i].customer.billingProfile[formLabels.invoiceDate.field.id]) && errors.form[i].customer.billingProfile[formLabels.invoiceDate.field.id]}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    {item.customer[formLabels.accountBillingProfileId.field.id] && <Col xs={12} lg={2}>
                        <Form.Group>
                            <Form.Control as={'div'} className={'mb-0'} plaintext readOnly>
                                {(billingType == BillingTypes.Purchase) ? formLabels.invoiceNumber.labelPurchase : (billingType == BillingTypes.Expense) ? formLabels.invoiceNumber.labelExpense : formLabels.invoiceNumber.label}
                                {formLabels.invoiceNumber.required && <span className={'text-danger ps-1'}>*</span>}

                                {item.siteJobs.some(site => site.jobs.some(x => x.isBillable)) && !(status && (selectedTab == 1)) && <>
                                    {
                                        (resetInvoiceNumber.index == i)
                                        ?
                                        <span className={'float-end'}><Spinner animation="border" size={'sm'} /></span>
                                        :
                                        (!resetInvoiceNumber.isLoading || resetInvoiceNumber.index == i) && <a href={'/'}
                                            className={'link-primary-underline float-end'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                if(!resetInvoiceNumber.isLoading){
                                                    dispatch(invoiceNumberForm.callResetInvoiceNumberApi({
                                                        index: i,
                                                        billingType: billingType,
                                                        accountBillingProfileId: item.customer[formLabels.accountBillingProfileId.field.id],
                                                        invoiceDate: item.customer.billingProfile[formLabels.invoiceDate.field.id],
                                                        callback: (prefix, number) => {
                                                            if(billingType == BillingTypes.Purchase){
                                                                setFieldValue('form.' + i + '.customer.billingProfile.purchaseOrderNumberPrefix', prefix);
                                                            } else if(billingType == BillingTypes.Expense){
                                                                setFieldValue('form.' + i + '.customer.billingProfile.expenseNumberPrefix', prefix);
                                                            } else {
                                                                setFieldValue('form.' + i + '.customer.billingProfile.invoiceNumberPrefix', prefix);
                                                            }
                                                            setFieldValue('form.' + i + '.customer.billingProfile.' + formLabels.invoiceNumber.field.id, number);
                                                        }
                                                    }));
                                                }
                                            }}
                                        >Reset</a>
                                    }
                                </>}
                            </Form.Control>
                            <InputGroup>
                                <InputGroup.Text>{(billingType == BillingTypes.Purchase) ? item.customer.billingProfile.purchaseOrderNumberPrefix : (billingType == BillingTypes.Expense) ? item.customer.billingProfile.expenseNumberPrefix : item.customer.billingProfile.invoiceNumberPrefix}-</InputGroup.Text>
                                <Form.Control
                                    {...formLabels.invoiceNumber.field}

                                    placeholder={(billingType == BillingTypes.Purchase) ? formLabels.invoiceNumber.labelPurchase : (billingType == BillingTypes.Expense) ? formLabels.invoiceNumber.labelExpense : formLabels.invoiceNumber.field.label}
                                    isInvalid={!!(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer.billingProfile && errors.form[i].customer.billingProfile[(billingType == BillingTypes.Purchase) ? formLabels.invoiceNumber.idPurchase : (billingType == BillingTypes.Expense) ? formLabels.invoiceNumber.idExpense : formLabels.invoiceNumber.field.id])}
                                    readOnly={(status === true) ? (selectedTab == 1) : !item.siteJobs.some(site => site.jobs.some(x => x.isBillable))}
                                    
                                    value={item.customer.billingProfile[formLabels.invoiceNumber.field.id]}
                                    onChange={(e) => {
                                        const { name, value } = e.target;
                                        setFieldValue('form.' + i + '.customer.billingProfile.' + name, value);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer.billingProfile && errors.form[i].customer.billingProfile[formLabels.invoiceNumber.field.id]) && errors.form[i].customer.billingProfile[formLabels.invoiceNumber.field.id]}</Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>}

                    {(item.customer.billingProfile.isConnectQuickbook || item.customer.billingProfile.isConnectXero) && (
                        <Col xs={12} lg={item.customer.billingProfile.isConnectXero ? 4 : 2} xl={3}>
                            {item.customer.billingProfile.isConnectQuickbook && (
                                <Form.Group>
                                    <Form.Control as={'div'} className={'mb-0'} plaintext readOnly>
                                        {formLabels.quickBookDefaultTermId.label}
                                        {formLabels.quickBookDefaultTermId.required && <span className={'text-danger ps-1'}>*</span>}
                                    </Form.Control>
                                    <Form.Control
                                        {...formLabels.quickBookDefaultTermId.field}
                                        isInvalid={!!(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer[formLabels.quickBookDefaultTermId.field.id])}
                                        readOnly={(status === true) ? (selectedTab == 1) : !item.siteJobs.some(site => site.jobs.some(x => x.isBillable))}
        
                                        as = {RSuiteSelectPicker}
                                        items={creditTerms.data}
                                        isLoading={creditTerms.isLoading}
                                        valueKey={formLabels.quickBookDefaultTermId.field.id}
                                        labelKey={formLabels.quickBookDefaultTermId.field.name}
                                        searchable={true}
                                        cleanable={true}
                                        isDefault={true}
                                        onClick={() => {
                                            dispatch(invoiceNumberForm.callReadCreditTermsApi(item.customer.billingProfile.quickBookApp.quickBookAppId))
                                        }}
                                        onClear={() => {
                                            setFieldValue('form.' + i + '.customer.' + formLabels.quickBookDefaultTermId.field.id, null);
                                            setFieldValue('form.' + i + '.customer.' + formLabels.quickBookDefaultTermId.field.name, '');
                                            setFieldValue('form.' + i + '.customer.' + formLabels.xeroPaymentTerm.field.name, null);
                                        }}
        
                                        value={item.customer[formLabels.quickBookDefaultTermId.field.id]}
                                        selectedName={item.customer[formLabels.quickBookDefaultTermId.field.name]}
                                        onSelect={(value, item) => {
                                            setFieldValue('form.' + i + '.customer.' + formLabels.quickBookDefaultTermId.field.id, value);
                                            setFieldValue('form.' + i + '.customer.' + formLabels.quickBookDefaultTermId.field.name, item[formLabels.quickBookDefaultTermId.field.name]);
                                            setFieldValue('form.' + i + '.customer.' + formLabels.xeroPaymentTerm.field.name, null);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">{(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer[formLabels.quickBookDefaultTermId.field.id]) && errors.form[i].customer[formLabels.quickBookDefaultTermId.field.id]}</Form.Control.Feedback>
                                </Form.Group>
                            )}

                            {item.customer.billingProfile.isConnectXero && (
                                <Form.Group>
                                    <Form.Control as={'div'} className={'mb-0'} plaintext readOnly>
                                        {formLabels.xeroPaymentTerm.label}
                                        {formLabels.xeroPaymentTerm.required && <span className={'text-danger ps-1'}>*</span>}
                                    </Form.Control>
                                    <InputGroup>
                                        <Form.Control
                                            {...formLabels.xeroPaymentTerm.field}
                                            isInvalid={!!(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer[formLabels.xeroPaymentTerm.field.id])}
                                            readOnly={(status === true) ? (selectedTab == 1) : !item.siteJobs.some(site => site.jobs.some(x => x.isBillable))}
                                            
                                            value={item.customer[formLabels.xeroPaymentTerm.field.id]}
                                            onChange={(e) => {
                                                const { name, value } = e.target;
                                                setFieldValue('form.' + i + '.customer.' + name, value);
                                            }}
                                        />
                                        <StyledDropdownButton
                                            title={Utils.getXeroPaymentTermTypeName(item.customer[formLabels.xeroPaymentTermType.field.id])}
                                            variant={'secondary'}
                                            menuAlign={'right'}
                                        >
                                            {XeroPaymentTermTypeItems.map((itm, j) => {
                                                let xeroPaymentTermType = (item && item.customer && item.customer[formLabels.xeroPaymentTermType.field.id]) ? item.customer[formLabels.xeroPaymentTermType.field.id] : false;
                                                return <Dropdown.Item
                                                    className={'py-2'}
                                                    key={itm.value + '_' + itm.title + '_' + i}
                                                    active={(itm.value === xeroPaymentTermType)}
                                                    onClick={async () => {
                                                        setFieldValue('form.' + i + '.customer.' + formLabels.xeroPaymentTermType.field.name, itm.value);
                                                    }}
                                                >{itm.title}</Dropdown.Item>
                                            })}
                                        </StyledDropdownButton>
                                        <Form.Control.Feedback type="invalid">{(errors && errors.form && errors.form[i] && errors.form[i].customer && errors.form[i].customer[formLabels.xeroPaymentTerm.field.id]) && errors.form[i].customer[formLabels.xeroPaymentTerm.field.id]}</Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            )}
                        </Col>
                    )}

                </Row>
            </Col>
        </>
    }

    const sitesForm = (formOptions, customer, i) => {
        let {
            setFieldValue,
            values,
        } = formOptions;

        return <Col xs={12} className={'mb-3 px-5 px-md-10'}>
            {
                ((customer.siteJobs && customer.siteJobs.length > 0) && (customer.siteJobs.some(x => x.jobs.length > 0)))
                ?
                <Row>
                    <Col xs={12} lg={4} xl={3}>
                        <Form.Control className={'mb-0'} plaintext readOnly defaultValue={'Site name'} />
                    </Col>
                    <Col xs={12} lg={8} xl={9}>
                        {/* <Row className='align-items-center' style={{ marginRight: '3px' }}> */}
                            {/* <Col xs={12} sm={6} lg={5} xl={5}>
                                <Form.Control className={'mb-0'} plaintext readOnly defaultValue={'Delivery orders'} />
                            </Col> */}
                            {/* <Col xs={12} sm={6} lg={7} xl={7}> */}
                                {/* <Form.Control className={'mb-0'} plaintext readOnly defaultValue={'Weigh chit'} /> */}
                                {/* <StyledCheck
                                    id={name + '_' + i + '_main_wc_selectAll'}
                                    label={'Select all'}
                                    // checked={values.customFormParams.isSendEmail}
                                    onChange={async (e) => {
                                        // await setFieldValue('customFormParams.isSendEmail', e.target.checked);
                                    }}
                                /> */}
                            {/* </Col> */}
                        {/* </Row> */}
                    </Col>
                </Row>
                :
                null
            }

            {(customer.siteJobs && customer.siteJobs.length > 0) && <Row>
                {customer.siteJobs.map((site, j) => {
                    return <React.Fragment key={name + '_site_' + j}>
                        {(site.jobs && site.jobs.length > 0) && <>
                            <Col xs={12} lg={4} xl={3}>
                                <Form.Label className={'mb-0'}>{site.siteName}</Form.Label>
                            </Col>
                            <Col xs={12} lg={8} xl={9}>
                                <Row style={{ marginRight: '3px' }}>
                                    <Col xs={12} sm={6} lg={5} xl={5}>
                                        Delivery orders
                                    </Col>
                                    <Col xs={12} sm={6} lg={7} xl={7}>
                                        <StyledCheck
                                            id={name + '_' + i + '_weigh_chit_' + '_' + j + '_selectAll'}
                                            label={'Select all'}
                                            checked={isCheckedAllJobs(site)}
                                            onChange={async (e) => {
                                                dispatch(invoiceNumber.selectAllWeightChitJobIds({ checked: e.target.checked, customerSiteId: site.customerSiteId, ids: getAllJobsBySiteId(site) }))
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={4} xl={3}></Col>
                            <Col xs={12} lg={8} xl={9}>
                                {
                                (site.jobs && site.jobs.length > 0)
                                ?
                                <Row style={{ maxHeight: '215px', overflowY: 'scroll', paddingTop: '3px' }}>
                                    {site.jobs.map((job, k) => {
                                        return <React.Fragment key={name + '_job_' + k}>
                                            <Col xs={12} sm={6} lg={5} xl={5}>
                                                {
                                                    job.isBillable
                                                    ?
                                                    <a href={'/billing/billing-form?id=' + job.jobId + '&billingType=' + billingType}
                                                        target={'_blank'}
                                                        rel="noreferrer"
                                                        className={'link-primary-underline'}
                                                    >
                                                        {job.jobNumber}
                                                    </a>
                                                    :

                                                    <Tooltip
                                                        title={'Job is not billable'}
                                                    >
                                                        <a href={'/billing/billing-form?id=' + job.jobId + '&billingType=' + billingType}
                                                            target={'_blank'}
                                                            rel="noreferrer"
                                                            className={'link-warning-underline'}
                                                        >
                                                            {job.jobNumber}
                                                        </a>
                                                    </Tooltip>
                                                }

                                                {((!status && (selectedTab == 1)) || (status && (selectedTab == 2))) && <StyledX href={'/'}
                                                    className={'link-danger ps-1'}
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        // let newJobs = site.jobs.filter(x => x.jobId != job.jobId);
                                                        // setFieldValue('form.' + i + '.siteJobs.'+ j + '.jobs', newJobs);

                                                        let testValues = _.cloneDeep(values);
                                                        testValues.form[i].siteJobs[j].jobs = testValues.form[i].siteJobs[j].jobs.filter(x => x.jobId != job.jobId);
                                                        
                                                        const filteredValues = testValues.form.map((customer) => ({
                                                            ...customer,
                                                            siteJobs: customer.siteJobs.filter((siteJob) => siteJob.jobs.length > 0).map((siteJob) => ({
                                                                ...siteJob,
                                                                jobs: siteJob.jobs.filter((job) => job !== null)
                                                                // jobs: siteJob.jobs.filter((job) => job !== null && job.isBillable)
                                                            })),
                                                            hasBillable: customer.siteJobs.some(site => site.jobs.some(x => x.isBillable))
                                                        })).filter((customer) => customer.siteJobs.length > 0);
                                                        // })).filter((customer) => customer.siteJobs.length > 0 && customer.siteJobs.some(site => site.jobs.some(x => x.isBillable)));
                                                        await setFieldValue('form', filteredValues);
                                                        
                                                        getCount(filteredValues);

                                                        // if(props.onCountChange){
                                                        //     props.onCountChange(getCount(filteredValues));
                                                        // }

                                                        if (filteredValues && filteredValues.length === 0){
                                                            if(props.onHide){
                                                                props.onHide();
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <i className={'material-icons'}>close</i>
                                                </StyledX>}
                                            </Col>
                                            <Col xs={12} sm={6} lg={7} xl={7}>
                                                {job.haveWeightChit && <StyledCheck
                                                    label={
                                                        (job.weightChitTicketNumber && job.weightChitTicketNumber != '')
                                                        ?
                                                        <a href={'/Jobs?weigh_chit=' + job.jobId}
                                                            target={'_blank'}
                                                            rel="noreferrer"
                                                            className={'link-primary-underline'}
                                                        >
                                                            {'weigh-' + ((job.weightChitTicketNumber && job.weightChitTicketNumber != '') ? job.weightChitTicketNumber : '')}
                                                        </a>
                                                        :
                                                        <div
                                                            onClick={() => {
                                                                dispatch(invoiceNumber.changeWeightChitJobIds({ customerSiteId: site.customerSiteId, jobId: job.jobId }))
                                                            }}
                                                        >{'weigh-'}</div>
                                                    }
                                                    checked={isCheckedJob(site.customerSiteId, job.jobId)}
                                                    onChange={async (e) => {
                                                        dispatch(invoiceNumber.changeWeightChitJobIds({ customerSiteId: site.customerSiteId, jobId: job.jobId }))
                                                    }}
                                                />}
                                            </Col>
                                        </React.Fragment>
                                    })}
                                </Row>
                                :
                                null    
                                }
                            </Col>
                            <Col xs={12}><StyledHr /></Col>
                        </>}
                    </React.Fragment>
                })}

            </Row>}
            
            {customer.siteJobs.some(site => site.jobs.some(x => !x.isBillable)) && <Row>
                <Col xs={12} className={'my-3'}>
                    <Form.Group>
                        <Form.Control className={'mb-0'} plaintext readOnly defaultValue={'Error message'} />
                        <StyledWarning>Invoices cannot be pushed for jobs that are not billable <b>(These jobs will be ignored if you continue)</b></StyledWarning>
                    </Form.Group>
                </Col>    
            </Row>}
        </Col>
    }

    const errorForm = (formOptions, customer, i) => {
        let {
            validateForm,
            values,
        } = formOptions;


        return <>
            <Col xs={12} className={'mb-3 px-5 px-md-10'}>
                <Form.Group>
                    <Form.Control className={'mb-0'} plaintext readOnly defaultValue={'Error message'} />
                    {customer && customer.siteJobs.map((site, s) => {
                        return <React.Fragment key={'error_siteJobs_' + i + '_' + s}>
                            {site && site.jobs.map((job, j) => {
                                let jobNumber = (job && job.response && job.response.jobNumber != '') ? job.response.jobNumber : '';
                                let message = (job && job.response && job.response.message != '') ? jobNumber + ' - ' + job.response.message : '';

                                if ((job && job.response) && (message != '')){
                                    return <React.Fragment key={'error_siteJobs_jobs_' + i + '_' + s + '_' + j}>
                                        {(job && job.response) && <b className={'text-danger'} dangerouslySetInnerHTML={{ __html: message }} />}
                                        <br />
                                    </React.Fragment>
                                }
                            })}
                        </React.Fragment>
                    })}
                </Form.Group>
            </Col>
            <Col xs={12} className={'mb-3 px-5 px-md-10'}>
                <Button 
                    type={'button'}
                    size={'sm'}
                    disabled={isLoading}
                    onClick={() => {
                        validateForm().then(async (err) => {
                            try {
                                await validationSchema.validate({ form: [ customer ]}, { abortEarly: false });
                                await dispatch(invoiceNumber.changeCustomerValue(customer));
                                await dispatch(invoiceNumber.callRetryApi(customer));
                              } catch (errors) {
                                errors.inner.forEach((error) => {
                                    Utils.toast(error.message, 'error');
                                });
                              }
                        })
                    }}
                >
                    Retry
                    {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                </Button>
            </Col>
        </>
    }

    return <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        initialValues={formValues}
        enableReinitialize={enableReinitialize}
        validateOnMount={validateOnMount}
        validateOnChange={validateOnChange}
        onSubmit={async (form) => {
            await dispatch(invoiceNumberForm.setForm(form.form))

            if(props.onSubmit){
                props.onSubmit(form.form);
            }
        }}
    >
        {(formOptions) => {
            return <Form className='w-100 p-5' autoComplete="off" noValidate onSubmit={formOptions.handleSubmit}>
                <FieldArray name={'form'}>
                    {(childFormOptions) => (
                        (formOptions && formOptions.values.form && formOptions.values.form.length > 0)
                        ?
                        formOptions.values.form.map((customer, i) => {
                            return <Row key={name + '_customer_' + i} className={'mb-5'}>
                                {customer.siteJobs.some(x => x.jobs.length > 0) && <>
                                    {customerForm(formOptions, customer, i)}
                                    {sitesForm(formOptions, customer, i)}
                                    {status && (selectedTab == 2) && errorForm(formOptions, customer, i)}
                                    {(i < (formOptions.values.form.length - 1)) && <Col><hr /></Col>}
                                </>}
                            </Row>
                        })
                        :
                        <Row>
                            {status && <Col xs={12} className={'mb-3 px-5 px-md-10 mt-10 text-center'}>
                                {!isLoading && <Form.Group>
                                    <Form.Label className={'mb-0'}>{'There are no jobs'}</Form.Label>
                                </Form.Group>}
                            </Col>}
                        </Row>
                    )}
                </FieldArray>
            </Form>
        }}
    </Formik>
});


export default TabForm;
